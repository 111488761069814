import formatCurrency from "../../Biblioteca/Funcoes/formatCurrency"
import converterData from "../../Biblioteca/Funcoes/converterData"

const CaixaEdicoes = ({ edicoes, componentePai }) => {
    return (
        <>
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    {componentePai === "dfdExtra" ? (<>SUGESTÕES DE EDIÇÃO NESTE DFD</>) : (<>EDIÇÕES REALIZADAS NESTE PCA</>)}
                </div>
                {edicoes.map((edicao, index) => (
                    <table className="tableDfd">
                        <thead>
                            <tr>
                                <th className="thDfd" style={{width: "15vw"}}>PROCESSO(PBDOC)</th>
                                <th className="thDfd" style={{width: "10vw"}}>DATA</th>
                                <th className="thDfd" style={{width: "20vw"}}>OBJETO</th>
                                <th className="thDfd">REGIONAL</th>
                                <th className="thDfd">QTD ANTERIOR</th>
                                <th className="thDfd">QTD ATUAL</th>
                                <th className="thDfd">VALOR ADICIONADO AO PCA</th>
                            </tr>
                        </thead>
                        <tbody>
                            {edicao.edicoes.map((el, index) => (
                                <tr key={`key-${el.objeto}-${index}`}>
                                    <td className="tdDfd">{edicao?.pbdoc || ""}</td>
                                    <td className="tdDfd">{converterData(edicao.data.slice(0,10))}</td>
                                    <td className="tdDfd">{el.objeto}</td>
                                    <td className="tdDfd">{el.regional}</td>
                                    <td className="tdDfd">{el.qtdAnterior}</td>
                                    <td className="tdDfd">{el.qtdNova}</td>
                                    <td className="tdDfd">{formatCurrency(el.diferenca)}</td>
                                </tr>
                            ))}
                        </tbody>
                        <thead>
                            <tr>
                                <th colSpan="6" className="thDfd" style={{
                                    backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                }}>
                                    TOTAL ADICIONADO AO PCA
                                </th>
                                <th colSpan="1" className="thDfd" style={{
                                    backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                }}>
                                    {RenderSomaTotal(edicao)}
                                </th>
                            </tr>
                        </thead>
                    </table>
                ))}
            </div>
        </>
    )

    function RenderSomaTotal(edicao) {
        let somaTotal = 0
        edicao.edicoes.forEach(item => somaTotal += item.diferenca)
        return formatCurrency(somaTotal)
    }
}

export default CaixaEdicoes
