import { useState, useEffect } from 'react'
import axios from 'axios'
import Select from '../../Biblioteca/Select'
import PrintDfdGeral from './PrintDfdGeral'

const SetAno = () => {
    const [versoesk, setVersoes] = useState([])
    const [anoPreenchimento, setAnoPreenchimento] = useState("")
    const [fonte, setFonte] = useState("")

    // useEffect(() => {
    //     axios.post('/api/pca/getVersoesPca', {fonte})
    //         .then(res => setVersoes(res.data.versoes))
    //         .catch(error => alert(error.response.data.message))
    // }, [fonte])

    const updateAno = (e) => {
        const { value } = e.target
        setAnoPreenchimento(value)
    }
    const updateFonte = (e) => {
        const { value } = e.target
        setFonte(value)
    }

    return (
        <>
            {anoPreenchimento === "" ? (
                <div style={{ margin: "1vw 0 0 4vw" }}>
                    <Select texto="Fonte" nome="fonte" valor={fonte}
                        funcao={updateFonte} widthWeb="26vw" widthMobile="80vw"
                        options={["CBM", "FUNESBOM"]} />
                    <Select texto="Ano de Preenchimento" tipo="number" nome="anoPreenchimento" valor={anoPreenchimento}
                        funcao={updateAno} widthWeb="26vw" widthMobile="80vw"
                        options={fonte === "CBM" ? [2024, 2025] : [2023, 2024, 2025]} />
                        {/* ...versoes ]} /> */}
                </div>
            ) : (
            <>
                {fonte !== "" && anoPreenchimento !== "" ? <PrintDfdGeral fonte={fonte} ano={anoPreenchimento}/> : <></>}
            </>)}
        </>
    )
}

export default SetAno