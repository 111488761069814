import React, { useState, useEffect, useRef } from 'react'
import axios from "axios"

import './CplDal.css'

import Select from '../Biblioteca/Select'
import Input from '../Biblioteca/Input'
import Botao from '../Biblioteca/Botao'
import formatCurrency from "../Biblioteca/Funcoes/formatCurrency"
import enviar from '../Biblioteca/Funcoes/enviar'

const CplDal = () => {
    const [area, setArea] = useState("")
    const [filtro, setFiltro] = useState("")
    const [anoPreenchimento, setAnoPreenchimento] = useState("")
    const [dados, setDados] = useState([])
    const [modificacoes, setModificacoes] = useState({})
    const textAreaRef = useRef([])

    useEffect(() => {
        alert('Aviso: Só é possível editar os campos "Objeto" e "Descrição" se a área selecionada for diferente de "Todas as áreas"')
    }, [])

    useEffect(() => {
        if (area !== "" && anoPreenchimento !== "") {
            axios.post(`/api/pca/getDfdByArea`, { area: area, anoPreenchimentoPca: anoPreenchimento })
                .then(res => setDados(res.data))
                .catch(error => alert(error.response.data.message))
        }
    }, [anoPreenchimento])

    const updateArea = (e) => {
        const { value } = e.target
        setArea(value)
    }
    const updateFiltro = (e) => {
        const { value } = e.target
        setFiltro(value)
    }
    const updateAno = (e) => {
        const { value } = e.target
        setAnoPreenchimento(value)
    }

    const aplicarFiltro = (dados) => {
        if (!filtro || filtro === "Sem filtro") return dados

        const filtros = {
            "Cód. Central Vazio": (item) => !item.codCentral,
            "CATMAT/SER Vazio": (item) => !item.catMatSer,
            "Ação Vazia": (item) => !item.acao,
            "Natureza Vazia": (item) => !item.natureza,
        }

        return dados.filter(filtros[filtro])
    }

    useEffect(() => {
        if (area !== "Todas as áreas") {
            textAreaRef.current.forEach(textArea => {
                if (textArea) {
                    textArea.style.height = 'auto'
                    textArea.style.height = textArea.scrollHeight + 'px'
                }
            })
        }
    }, [dados, filtro])

    const handleInputChange = (e, id) => {
        var { name, value } = e.target
        if (name === "valorEstimado") {
            value = value.replace(/R\$|\s|\./g, '').replace(',', '.')
            value = parseFloat(value)
        }
        setModificacoes(prevState => {
            const existingEntry = prevState[id]
            if (existingEntry) {
                return { ...prevState, [id]: { ...existingEntry, [name]: value } }
            } else {
                return { ...prevState, [id]: { id: id, [name]: value } }
            }
        })
    }

    const dadosFiltrados = aplicarFiltro(dados)

    return (
        <>
            <div style={{ margin: "1vw 0 0 4vw" }}>
                <Select texto="Área" nome="area" valor={area}
                    funcao={updateArea} widthWeb="26vw" widthMobile="80vw"
                    options={["Todas as áreas", "Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                        "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                        "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                        "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                        "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                        "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                        "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                        "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais",
                        "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra", ,
                        "Seleções internas e Concursos Público",
                        "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                        "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição", "Sem CAT"]} />
                <Select texto="Filtro" nome="filtro" valor={filtro}
                    funcao={updateFiltro} widthWeb="26vw" widthMobile="80vw"
                    options={["Sem filtro", "Cód. Central Vazio", "CATMAT/SER Vazio", "Ação Vazia", "Natureza Vazia"]} />
                <Select texto="Ano de Preenchimento PCA" nome="anoPreenchimentoPca" valor={filtro}
                    funcao={updateAno} widthWeb="26vw" widthMobile="80vw"
                    options={["Todos os anos", 2024, 2025]} />
            </div>
            <div style={{ marginBottom: '2rem' }}>
                {dados.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "10vw" }}>Objeto</th>
                                <th style={{ width: "20vw" }}>Descrição</th>
                                <th>UND</th>
                                <th>Central de Compras</th>
                                <th>CATMAT/SER</th>
                                <th>Descrição CAT</th>
                                <th>Valor Estimado</th>
                                <th>Ação</th>
                                <th>Natureza</th>
                                <th>Área</th>
                                <th>Editar</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: "10px" }}>
                            {dadosFiltrados.map((item, indexItem) => (
                                <tr key={indexItem}>
                                    {area === "Todas as áreas" ? (
                                        <td>{item.objeto}</td>
                                    ) : (
                                        <td>
                                            <textarea
                                                ref={el => textAreaRef.current.push(el)}
                                                className="input-objeto-descricao" name="objeto"
                                                value={modificacoes[item._id] ? modificacoes[item._id].objeto : item.objeto}
                                                onChange={(e) => handleInputChange(e, item._id)}
                                            />
                                        </td>
                                    )}
                                    {area === "Todas as áreas" ? (
                                        <td>{item.descricao}</td>
                                    ) : (
                                        <td>
                                            <textarea
                                                ref={el => textAreaRef.current.push(el)}
                                                className="input-objeto-descricao" name="descricao"
                                                value={modificacoes[item._id] ? modificacoes[item._id].descricao : item.descricao}
                                                onChange={(e) => handleInputChange(e, item._id)}
                                            />
                                        </td>
                                    )}
                                    {area === "Todas as áreas" ? (
                                        <td>{item.und}</td>
                                    ) : (
                                        <td>
                                            <input
                                                className="input-outros" name="und"
                                                value={modificacoes[item._id] ? modificacoes[item._id].und : item.und}
                                                onChange={(e) => handleInputChange(e, item._id)}
                                            />
                                        </td>
                                    )}
                                    <td>
                                        <Input key={`codCentral-${item._id}`} tipo="number" nome="codCentral" widthWeb="100%"
                                            valor={modificacoes[item._id] ? modificacoes[item._id].codCentral : item.codCentral}
                                            funcao={handleInputChange} additionalArgs={item._id} />
                                    </td>
                                    <td>
                                        <Input key={`catMatSer-${item._id}`} tipo="number" nome="catMatSer" widthWeb="100%"
                                            valor={(modificacoes[item._id]?.catMatSer ?? item.catMatSer)?.toString().replace(/ S$/, '') || ''}
                                            funcao={handleInputChange} additionalArgs={item._id} />
                                    </td>
                                    <td>
                                        {item?.descricaoCatMatSer}
                                    </td>
                                    <td>
                                        <Input key={`valoreEstimado-${item._id}`} tipo="moeda" nome="valorEstimado" widthWeb="100%"
                                            valor={modificacoes[item._id] ? modificacoes[item._id].valorEstimado : item.valorEstimado}
                                            funcao={handleInputChange} additionalArgs={item._id} />
                                    </td>
                                    <td>
                                        <Select key={`acao-${item._id}`} nome="acao" widthWeb="100%"
                                            funcao={handleInputChange} additionalArgs={item._id}
                                            valor={modificacoes[item._id] ? modificacoes[item._id].acao : item.acao}
                                            options={["4211", "4216", "2143", "1157", "1614", "2216", "2217",
                                                "4392", "4393", "4938", "6106", "0703", "751", "4391", "4780"]} />
                                    </td>
                                    <td>
                                        <Select key={`natureza-${item._id}`} nome="natureza" widthWeb="100%"
                                            funcao={handleInputChange} additionalArgs={item._id}
                                            valor={modificacoes[item._id] ? modificacoes[item._id].natureza : item.natureza}
                                            options={["339030", "339033", "339039", "339139", "449039",
                                                "449051", "449052", "449092"]} />
                                    </td>
                                    <td>
                                        <Select key={`areaEipcp-${item._id}`} nome="areaEipcp" widthWeb="100%"
                                            funcao={handleInputChange} additionalArgs={item._id}
                                            valor={modificacoes[item._id] ? modificacoes[item._id].areaEipcp : item.areaEipcp}
                                            options={["Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                                                "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                                                "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                                                "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                                                "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                                                "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                                                "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                                                "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais",
                                                "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra", ,
                                                "Seleções internas e Concursos Público",
                                                "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                                                "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição"]} />
                                    </td>
                                    <td>
                                        <Botao texto="Salvar" widthWeb="6vw"
                                            funcao={() => enviar('/api/pca/salvarCplDal', modificacoes[item._id])} />
                                    </td>
                                    <td>
                                        <Botao texto={item.desativado ? "Ativar" : "Desativar"} widthWeb="6vw" cor={item.desativado ? null : "#dc3545"}
                                            funcao={() => enviar('/api/pca/desativarItem', {id: item._id})} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (<></>)}
            </div>
        </>
    )
}

export default CplDal
