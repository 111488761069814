import React, { useContext, useState, useEffect } from 'react';
import { MyContext } from "../Biblioteca/MyContext"

import axios from 'axios'

import Select from '../Biblioteca/Select'
import Botao from '../Biblioteca/Botao'
import enviar from '../Biblioteca/Funcoes/enviar'

const NavDfd = (props) => {
    const { myState } = useContext(MyContext);
    const [display, setDisplay] = useState({
        dfdExtra: "none"
    })

    const linkStyle = {
        marginLeft: 'auto',
        marginRight: '1rem',
        color: myState === "light" ? 'black' : "#F9F9F9",
        fontWeight: 550,
        fontSize: "13px",
        cursor: 'pointer',
        textDecoration: 'none',
    }

    const toggleDisplay = (key) => {
        setDisplay(prev => ({
            ...prev,
            [key]: prev[key] === "none" ? "block" : "none"
        }))
    }

    const [dadosUser, setDadosUser] = useState({ fonte: localStorage.fonte, regional: "" })
    const [subordinadasUser, setSubordinadasUser] = useState([])
    const [keyDiv, setKey] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resUser = await axios('/api/user/getFonteRegional')
                const userData = { ...resUser.data, userId: localStorage.id }
                setDadosUser(userData)
                setKey(prev => prev + 1)

                const resAno = await axios.get('/api/pca/getSituacaoPreenchimento')
                const resSubs = await axios.post('/api/pca/getUnidadesSub', {
                    anoPreenchimento: resAno.data.ano,
                    fonte: userData.fonte
                })
                setSubordinadasUser(resSubs.data)
                setKey(prev => prev + 1)
            } catch (err) {
                console.log(err)
            }
        }

        fetchData()
    }, [])

    const updateField = (e) => {
        const { value, name } = e.target
        const tempState = { ...dadosUser }
        tempState[name] = value
        setDadosUser(tempState)
    }

    if (window.matchMedia("(min-width:600px)").matches) {
        return (
            <div style={{ textAlign: "center", width: '100%' }}>
                <a href="/senha" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    SENHA
                </a>
                <a href="/" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    NOVO DFD
                </a>
                <a href="/SolicitarItem" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    INCLUIR ITEM
                </a>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>IMPRESSÃO DFD</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/SetAno" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    APENAS REGIONAL
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/SetAnoGeral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    REGIONAL + SUBORDINADAS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    CATS AGRUPADOS
                </a>
                {localStorage.regionalUser.includes("CRBM") ? (<>
                    <a href="/GerenciaUnidades" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                        GERENCIAR ORÇAMENTO
                    </a>
                </>) : (<></>)}
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>DFD EXTRA</span>
                    <div className="caixa1">
                        <div className="caixao">
                            <div className='mt-2'>
                                <a href="/DfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Novo DFD
                                </a>
                            </div>
                            <div className='mt-2'>
                                <a href="/GetDfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                                    Visualizar DFDs Extras
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dropdown" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                    <span>SETOR/FONTE</span>
                    <div className="caixa1">
                        <div className="caixao" style={{ padding: "5px" }}>
                            <div className='mt-2'>
                                <Select key={keyDiv} texto="Setor" nome="regional" valor={dadosUser.regional}
                                    funcao={updateField} widthWeb="16vw" widthMobile="80vw"
                                    options={subordinadasUser.map(el => el.regionalDepartamento)} />
                            </div>
                            <div className='mt-2'>
                                <Botao texto="Salvar"
                                    funcao={() => {
                                        enviar('/api/pca/updateUserInfo', dadosUser, localStorage.setItem("regionalUser", dadosUser.regional))
                                        window.location.reload()
                                    }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <>
                <div className="mt-2">
                    <a href="/senha" style={{ ...props.estilo, ...linkStyle }}>
                        SENHA
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/" style={{ ...props.estilo, ...linkStyle }}>
                        NOVO DFD
                    </a>
                </div>
                <div className="mt-2">
                    <a href="/SolicitarItem" style={{ ...props.estilo, ...linkStyle }}>
                        INCLUIR ITEM
                    </a>
                </div>
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('dfdExtra')}>
                        IMPRESSÃO DFD
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.dfdExtra }}>
                    <div className='mt-2'>
                        <a href="/SetAno" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            APENAS REGIONAL
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/SetAnoGeral" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            REGIONAL + SUBORDINADAS
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <a href="/catsagrupados" style={{ ...props.estilo, ...linkStyle }}>
                        CATS AGRUPADOS
                    </a>
                </div>
                {localStorage.regionalUser.includes("CRBM") ? (<>
                    <div className="mt-2">
                        <a href="/GerenciaUnidades" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            GERENCIAR ORÇAMENTO
                        </a>
                    </div>
                </>) : (<></>)}
                <div className="mt-2">
                    <a style={{ ...props.estilo, ...linkStyle }}
                        onClick={() => toggleDisplay('dfdExtra')}>
                        DFD EXTRA
                    </a>
                </div>
                <div className="mt-2 ms-2" style={{ display: display.dfdExtra }}>
                    <div className='mt-2'>
                        <a href="/DfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Novo DFD
                        </a>
                    </div>
                    <div className='mt-2'>
                        <a href="/GetDfdExtra" style={{ ...props.estilo, ...linkStyle, marginLeft: '2vw' }}>
                            Visualizar DFDs Extras
                        </a>
                    </div>
                </div>
            </>
        )
    }
}

export default NavDfd