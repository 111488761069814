import { useState, useEffect } from 'react'
import axios from 'axios'

import brasao from '../../../assets/brasao.png'
import FonteRecursosExtra from './FonteRecursosExtra'
import CaixaEdicoes from '../Impressoes/CaixaEdicoes'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const currentYear = new Date().getFullYear()

const DfdExtraView = () => {
    const [dados, setDados] = useState({})
    const id = window.location.pathname.slice(14)
    const [cmd, setCmd] = useState()

    useEffect(() => {
        axios.get(`/api/dfdExtra/getDfdsById/${id}`)
            .then(res => setDados(res.data))
            .catch(error => alert(error.response.data.message))
    }, [id])
    console.log(dados)
    useEffect(() => {
        const url = '/api/orcamento/getCmdAss'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setCmd(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])

    // Se o DfdExtra foi criado utilizando os campos "itensRemovidos" e "itensAdicionados",
    // vamos consolidar as alterações em um único array para exibição no resumo.
    const edicoes = [];
    if (dados && (dados.itensRemovidos || dados.itensAdicionados)) {
        const edicoesArray = [];

        if (dados.itensRemovidos) {
            dados.itensRemovidos.forEach(item => {
                edicoesArray.push({
                    objeto: item.objeto,
                    regional: item.regional || dados.regional, // caso item.regional não exista, usa a regional do documento
                    qtdAnterior: item.qtdRemovida, // ou, se houver informação do valor original, use-a
                    qtdNova: 0, // supondo que o item foi totalmente removido
                    diferenca: - (item.valorEstimado * item.qtdRemovida)
                });
            });
        }
        if (dados.itensAdicionados) {
            dados.itensAdicionados.forEach(item => {
                edicoesArray.push({
                    objeto: item.objeto,
                    regional: item.regional || dados.regional,
                    qtdAnterior: 0,
                    qtdNova: item.qtdAdicionada,
                    diferenca: item.valorEstimado * item.qtdAdicionada
                });
            });
        }

        edicoes.push({
            pbdoc: dados.pbdoc || "",
            data: dados.data,
            edicoes: edicoesArray
        });
    }

    return (
        <>
            {/* Cabeçalho */}
            <div style={{ textAlign: "center", marginTop: "4vh", fontWeight: "600", marginBottom: "5vh" }}>
                <img src={brasao} style={{ width: "8vw" }} alt="Brasão" />
                <div>ESTADO DA PARAÍBA</div>
                <div>SECRETARIA DE SEGURANÇA E DEFESA SOCIAL</div>
                <div>CORPO DE BOMBEIROS MILITAR DA PARAÍBA</div>
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                DOCUMENTO DE FORMALIZAÇÃO DE DEMANDA – DFD (EXTRA)
            </div>

            {/* Informações do Nível Setorial */}
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DO NÍVEL SETORIAL
                </div>
                <div style={{
                    borderBottom: "1px solid black",
                    padding: "5px 0 5px 5px"
                }}><b>NÍVEL SETORIAL: </b>{RenderNivelSetorial()}</div>
                <div style={{ borderBottom: "1px solid black", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>Bombeiro Militar responsável pelo Planejamento e esclarecimentos da Demanda: </b>
                        {localStorage.nome}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Matrícula CBMPB: </b> {localStorage.numeroMatricula}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>E-mail: </b> {localStorage.email}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Contato: </b> {localStorage.contato}
                    </div>
                </div>
            </div>

            {/* Informações da Necessidade de Contratação */}
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DA NECESSIDADE DE CONTRATAÇÃO
                </div>
                <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}>
                    <div style={{ textAlign: "center" }}>
                        <b>Justificativa da não inclusão no PCA e Resultados Pretendidos:</b>
                    </div>
                    <br />
                    {dados?.justificativaInclusao}
                </div>
                <FonteRecursosExtra dados={dados}
                    fonte={dados.fonte}
                    regional={dados.regional}
                    areaEipcp={dados.itensAdicionados ? dados?.itensAdicionados.map(item => item.areaEipcp) : []}
                // dfd={"geral"}
                />

                <table className="tableDfd" style={{width: "100%"}}>
                    <thead>
                        <tr>
                            {/* <th rowSpan="2" className="thDfdGeral" style={{ width: "3%", fontSize: "8px" }}>ITEM</th> */}
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6%", fontSize: "7px" }}>CÓDIGO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "8%", fontSize: "7px" }}>OBJETO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "18%", fontSize: "7px" }}>DESCRIÇÃO BASE DE REFERÊNCIA</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "8%", fontSize: "7px" }}>UND. DE MEDIDA</th>
                            <th className="thDfdGeral" colSpan="3" style={{ width: "15%", fontSize: "7px" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5%", fontSize: "7px" }}>QTD. ESTIMADA</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "18%", fontSize: "7px" }}>JUSTIFICATIVA DOS QUANTITATIVOS</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6%", fontSize: "7px" }}>VALOR ESTIMADO UNITÁRIO</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "10%", fontSize: "7px" }}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 1825</th>
                            <th rowSpan="2" className="thDfdGeral" style={{ width: "5%", fontSize: "7px" }}>GRAU DE PRIORIDADE</th>
                        </tr>
                        <tr>
                            <th className="thDfdGeral">2021</th>
                            <th className="thDfdGeral">2022</th>
                            <th className="thDfdGeral">2023</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.itensAdicionados && RenderRows(dados.itensAdicionados)}
                    </tbody>
                </table>

                {/* Em vez da tabela antiga de itens, renderizamos o resumo das edições */}
                {edicoes.length > 0 ? (
                    <CaixaEdicoes edicoes={edicoes} componentePai="dfdExtra" />
                ) : (<></>)}
                {dados.itensRemovidos && dados?.itensRemovidos.length > 0 ? (<>
                    <div style={{ borderTop: "1px solid black", borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}>
                        <div style={{ textAlign: "center" }}>
                            <b>Justificativa da remoção dos itens:</b>
                        </div>
                        <br />
                        {dados?.justificativaRemocao}
                    </div>
                </>) : (<></>)}
            </div>

            {/* Informações do Comandante */}
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0", fontWeight: "600",
                }}>
                    COMANDANTE / CHEFE / RESPONSÁVEL PELA UNIDADE REQUISITANTE
                </div>
                <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}>
                    <br />
                    Certifico que a formalização da demanda se faz necessária pelos motivos expostos na justificativa da área do presente documento em atendimento a Lei nº 14.133/2021, bem como na excepcionalidade prevista no Art. 13. do DECRETO Nº 44.639, de 22 de dezembro de 2023.
                </div>
                <br />
                <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}>
                    <br />
                    João Pessoa, datado via PBDOC.
                </div>
                <br />
                <div style={{ textAlign: "center", padding: "5px", fontWeight: "420" }}>
                    <br />
                    {cmd !== undefined ? (
                        <div style={{ textAlign: "center", marginTop: "8vh" }}>
                            <b>
                                <br />
                                {cmd.nome} - {cmd.posto}<br />
                                {cmd.numeroMatricula} - {cmd.regional}
                            </b>
                        </div>
                    ) : null}
                    Assinado e datado via PBDOC
                </div>
                <br />
            </div>

            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    AUTORIDADE COMPETENTE
                </div>
                <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                    Diante do exposto, <b style={{ fontWeight: "600" }}>APROVO</b> as citadas substituições para que os
                    setores envolvidos realizem os ajustes necessários para fins da adequação do
                    orçamento e da execução da presente demanda.
                </div><br />
                <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                    João Pessoa, datado via PBDOC.
                </div><br />
                <div style={{ textAlign: "center", padding: "5px", fontWeight: "320" }}><br />
                    {cmd !== undefined ? (
                        <div style={{ textAlign: "center", marginTop: "8vh" }}><b>
                            <br />
                            MARCELO AUGUSTO DE ARAÚJO BEZERRA – CEL QOBM<br />
                            Comandante Geral do CBMPB<br />
                            MAT. 516.508-3<br />
                        </b></div>
                    ) : (<></>)}
                    Assinado e datado via PBDOC
                </div><br />
            </div>
        </>
    )

    function RenderNivelSetorial() {
        const regs = ["1º CRBM", "2º CRBM", "3º CRBM", "4º CRBM", "CBMPB"]
        return regs.includes(localStorage.regionalUser)
            ? localStorage.regionalUser
            : `Departamento (${localStorage.regionalUser})`
    }

    function RenderRows(array) {
        let rows = []
        array.map((el, index) => {
            const descricao = el.descricao.length > 200 ?
                el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
            const media = (el.ano1 + el.ano2 + el.ano3) / 3
            var justificativa
            if (media === 0) justificativa = "Item que não teve compra nos últimos três anos, mas apresentaram demandas de acordo com as quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo"
            else if (media <= el.qtdAdicionada) justificativa = "Quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
            else justificativa = "Conforme JUSTIFICATIVA DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
            rows.push(
                <tr key={`objeto${index}`}>
                    {/* <td className="tdDfdGeral">{index + 1}</td> */}
                    <td className="tdDfdGeral">{el?.codCentral || "Sem correspondência no SGC"}</td>
                    <td className="tdDfdGeral">{el.objeto}</td>
                    <td className="tdDfdGeral">{descricao}</td>
                    <td className="tdDfdGeral">{el.und}</td>
                    <td className="tdDfdGeral">{el.ano1}</td>
                    <td className="tdDfdGeral">{el.ano2}</td>
                    <td className="tdDfdGeral">{el.ano3}</td>
                    <td className="tdDfdGeral">{el.qtdAdicionada}</td>
                    <td className="tdDfdGeral">{justificativa}</td>
                    <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                    <td className="tdDfdGeral">{formatCurrency(el.qtdAdicionada * el.valorEstimado)}</td>
                    <td className="tdDfdGeral">{el.prioridade}</td>
                </tr>
            )
        }
        )
        return rows
    }
}

export default DfdExtraView
