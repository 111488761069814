import { useState } from 'react'
import Select from '../../Biblioteca/Select'
import PrintDfdSetorizado from './PrintDfdStorizado'
import ImpressaoDfd from '../../EisenhowerMatrix/ImpressaoDfd'

const SetAno = () => {
    const [anoPreenchimento, setAnoPreenchimento] = useState("")

    const updateAno = (e) => {
        const { value } = e.target
        setAnoPreenchimento(value)
    }

    return (
        <>
            {anoPreenchimento === "" ? (
                <div style={{ margin: "1vw 0 0 4vw" }}>
                    <Select texto="Ano de Preenchimento" tipo="number" nome="anoPreenchimento" valor={anoPreenchimento}
                        funcao={updateAno} widthWeb="26vw" widthMobile="80vw"
                        options={[2023, 2024, 2025]} />
                </div>
            ) : (
            <>
                {anoPreenchimento === "2023" ? <ImpressaoDfd/> : <PrintDfdSetorizado ano={anoPreenchimento}/>}
            </>)}
        </>
    )
}

export default SetAno