import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "./MyContext";
import Moeda from '../Biblioteca/Funcoes/moeda';
import Cnpj from '../Biblioteca/Funcoes/cnpj';

const Input = (props) => {
  const { myState } = useContext(MyContext);
  const mediaWeb = window.matchMedia("(min-width:600px)").matches;
  const width = mediaWeb ? props.widthWeb : props.widthMobile || "15vw";

  const estilo = {
    divInput: {
      display: props.display || "inline-grid",
      textAlign: "center",
      width: width,
      margin: "auto",
      marginBottom: props.marginB || "1vh",
      marginRight: props.marginR || "2vw",
      marginLeft: props.marginL
    },
    label: {
      textAlign: "left",
      fontWeight: 600,
      fontSize: props.fontS || 15,
      color: myState === "light" ? "black" : "#dbdbdb"
    },
    input: {
      width: width,
      border: myState === "light" ? "1px solid #00000029" : "1px solid rgba(0,0,0,0.35)",
      borderRadius: "5px",
      color: myState === "light" ? "black" : "#dbdbdb",
      minHeight: "18px",
      fontSize: props.fontS || 15,
      backgroundColor: "transparent"
    }
  };

  // Estado interno para o valor do input
  const [valor, setValor] = useState(props.valor || "");

  // Atualiza o estado se props.valor mudar
  useEffect(() => {
    setValor(props.valor || "");
  }, [props.valor]);

  // Handler de mudança que atualiza o estado e propaga a mudança
  const handleChange = (e) => {
    setValor(e.target.value);
    if (props.funcao) {
      // Se precisar de alguma lógica especial (como para Moeda ou CNPJ), você pode chamar ela aqui
      props.funcao(e, props.additionalArgs);
    }
  };

  // Se o modo for "fixed", renderiza um valor não-editável
  if (props.modo === "fixed") {
    let data;
    if (props.tipo === "date" && props.valor) {
      const ano = props.valor.slice(0, 4);
      const mes = props.valor.slice(5, 7);
      const dia = props.valor.slice(8, 10);
      data = `${dia}/${mes}/${ano}`;
    } else {
      data = props.valor;
    }
    return (
      <div style={estilo.divInput}>
        <div style={estilo.label}>{props.texto}</div>
        <div style={{ ...estilo.input, textAlign: "left" }}>{data}</div>
      </div>
    );
  }

  // Renderização normal: para os tipos "moeda" e "cnpj" mantenha a lógica se necessário, ou adapte conforme preciso
  if (props.tipo === "moeda") {
    return (
      <div style={estilo.divInput}>
        <div style={estilo.label}>{props.texto}</div>
        <input
          style={estilo.input}
          onChange={e => Moeda(e, handleChange)}
          name={props.nome}
          placeholder="R$ 00.000,00"
          value={valor}
        />
      </div>
    );
  }
  if (props.tipo === "cnpj") {
    return (
      <div style={estilo.divInput}>
        <div style={estilo.label}>{props.texto}</div>
        <input
          style={estilo.input}
          onChange={e => Cnpj(e, handleChange)}
          name={props.nome}
          placeholder="00.000.000/0000-00"
          value={valor}
        />
      </div>
    );
  }

  return (
    <div style={estilo.divInput}>
      <div style={estilo.label}>{props.texto}</div>
      <input
        style={estilo.input}
        type={props.tipo || "text"}
        name={props.nome}
        placeholder={props.placeholder}
        value={valor}
        onChange={handleChange}
        min={props.min || 0}
      />
    </div>
  );
};

export default Input;
