import { useState, useEffect } from "react"
import axios from 'axios'

import './NovoDfd.css'
import Select from '../../Biblioteca/Select'
import formatCurrency from "../../Biblioteca/Funcoes/formatCurrency"
import Input from '../../Biblioteca/Input'
import enviar from '../../Biblioteca/Funcoes/enviar'
import Textarea from '../../Biblioteca/Textarea'
import Botao from '../../Biblioteca/Botao'
import SetoresQueEscolheram from "../Helpers/SetoresQueEscolheram"

const NovoDfd = ({ year }) => {
    const [area, setArea] = useState("")
    const [dados, setDados] = useState([])
    const [inputData, setInputData] = useState([])
    const [inputVersion, setInputVersion] = useState(0)
    const [infoDfd, setInfoDfd] = useState({ anoPreenchimentoPca: year, userId: localStorage.id, areaEipcp: area })
    const [saldo, setSaldo] = useState(null)

    const isCRBM = localStorage.regionalUser && localStorage.regionalUser.includes("CRBM")

    useEffect(() => {
        axios.post(`/api/pca/getSaldoDfd`, { anoPreenchimentoPca: year, userId: localStorage.id })
            .then(res => setSaldo(res.data))
            .catch(error => alert(error.response.data.message))

        axios.post(`/api/pca/getInfoDfd`, { anoPreenchimentoPca: year, userId: localStorage.id, areaEipcp: area })
            .then(res => setInfoDfd(prevInfo => ({
                ...prevInfo,
                justificativa: res.data.justificativa || '',
                resultados: res.data.resultados || '',
                boolVinculacao: res.data.boolVinculacao || '',
                vinculacao: res.data.vinculacao || ''
            })))
            .catch(error => alert(error.response.data.message))

        axios.post(`/api/pca/getDfdByArea`, { area: area, anoPreenchimentoPca: year })
            .then(res => setDados(res.data))
            .catch(error => alert(error.response.data.message))
    }, [area])

    const updateArea = (e) => {
        const { value } = e.target
        setArea(value)
        setInputData([])
        setInputVersion(prevVersion => prevVersion + 1)
        setInfoDfd(prevInfoDfd => ({
            ...prevInfoDfd,
            areaEipcp: value, justificativa: '', resultados: '', boolVinculacao: '', vinculacao: ''
        }))
    }

    const handleInfoDfd = (e) => {
        const { value, name } = e.target
        const tempState = { ...infoDfd }
        tempState[name] = value
        setInfoDfd(tempState)
    }

    const handleInputChange = (e, { id, indexRegional }) => {
        const { name, value } = e.target
    
        setInputData(prevState => {
            let regionalItem = null
            const foundItem = dados.find(item => item._id === id) || searchResults.find(item => item._id === id)
            if (foundItem) {
                regionalItem = foundItem.regionais[indexRegional]
            }
    
            const numericFields = ["ano1", "ano2", "ano3", "qtdEstimada"]
            let finalValue = value
            if (isCRBM && regionalItem && numericFields.includes(name)) {
                const minField = "min" + name.charAt(0).toUpperCase() + name.slice(1)
                const minValue = Number(regionalItem[minField]) || 0
                finalValue = Number(value) - minValue
            }
    
            let inputDataItem = prevState.find(item => item.id === id)
            if (!inputDataItem) {
                inputDataItem = regionalItem ? { id, ...regionalItem } : { id }
                inputDataItem[name] = finalValue
                return [...prevState, inputDataItem]
            } else {
                const updatedItem = { ...inputDataItem }
                if (regionalItem) {
                    Object.keys(regionalItem).forEach(key => {
                        if (updatedItem[key] === undefined) {
                            updatedItem[key] = regionalItem[key]
                        }
                    })
                }
                updatedItem[name] = finalValue
                return prevState.map(item => item.id === id ? updatedItem : item)
            }
        })
    }    

    const sendItem = (itemData) => {
        axios.post('/api/pca/updateObjDfd', { item: itemData, userId: localStorage.id, anoPreenchimentoPca: year })
            .then(res => {
                alert(res.data.msg)
                setSaldo(prevState => ({ ...prevState, saldo: res.data.saldo }))
            })
            .catch(error => alert(error.response.data.message))
    }

    const [search, setSearch] = useState({ anoPreenchimentoPca: year })
    const [searchResults, setSearchResults] = useState([])
    const updateSearch = (e) => {
        const { name, value } = e.target
        let tempState = { ...search }
        tempState[name] = value
        setSearch(tempState)
    }

    const buscaItem = () => {
        axios.post('/api/pca/buscarItemNovoDfd', search)
            .then(res => setSearchResults(res.data))
            .catch(error => {
                alert(error.response.data.message)
                setSearchResults([])
            })
    }

    return (
        <>
            <div className="caixaSaldo">
                {saldo && saldo.saldo ?
                    <div className="internoSaldo">
                        <div className="regionalSaldo">{saldo.regional}({saldo.fonte})</div>
                        {saldo.saldo ? <>{formatCurrency(saldo.saldo)}</> : <>R$ 0,00</>}
                    </div> :
                    <div className="internoSaldo">Carregando saldo...</div>
                }
            </div>
            <div style={{ margin: "1vw 0 0 4vw" }}>
                <div style={{ marginBottom: "2vw" }}>
                    <Input texto="Buscar Item" nome="item" widthWeb="26vw" widthMobile="80vw" funcao={updateSearch} />
                    <div style={{ marginBottom: "2vw" }}>
                        <Botao texto="BUSCAR" funcao={buscaItem}
                            align="left" widthMobile="40vw" display="inline" />
                        <Botao texto="LIMPAR" cor="red" funcao={() => setSearchResults([])}
                            align="left" widthMobile="40vw" display="inline" />
                    </div>

                    {searchResults.length > 0 ? (<>
                        <div className="scroll-tabela">
                            <table className="tabelaNovoDfd">
                                <thead>
                                    <tr>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "15vw" }}>OBJETO</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>UNIDADE</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>VALOR ESTIMADO</th>
                                        <th className="thNovoDfd" colSpan="3" style={{ width: "20vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>QTD ESTIMADA</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>PREVISÃO DE ENTREGA</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "20vw" }}>JUSTIFICATIVA (se necessário)</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>GRAU DE PRIORIDADE</th>
                                        <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}></th>
                                    </tr>
                                    <tr>
                                        <th className="thNovoDfd">{year - 3}</th>
                                        <th className="thNovoDfd">{year - 2}</th>
                                        <th className="thNovoDfd">{year - 1}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {searchResults.map((item, indexItem) => {
                                        const regionalData = item.regionais[0] || {}
                                        console.log(item)
                                        return (
                                            <tr key={indexItem}>
                                                <td className="tdNovoDfd">{item.objeto}</td>
                                                <td className="tdNovoDfd">{item.descricao}</td>
                                                <td className="tdNovoDfd">{item.und}</td>
                                                <td className="tdNovoDfd">{formatCurrency(item.valorEstimado)}</td>
                                                <td className="tdNovoDfd">
                                                    <Input
                                                        key={`input-ano1-${item._id}-${inputVersion}`}
                                                        valor={String(regionalData.ano1) || ""}
                                                        nome="ano1"
                                                        tipo="number"
                                                        widthWeb="6vw"
                                                        funcao={handleInputChange}
                                                        additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                        min={isCRBM ? regionalData.minAno1 : undefined}
                                                    />
                                                </td>
                                                <td className="tdNovoDfd">
                                                    <Input
                                                        key={`input-ano2-${item._id}-${inputVersion}`}
                                                        valor={String(regionalData.ano2) || ""}
                                                        nome="ano2"
                                                        tipo="number"
                                                        widthWeb="6vw"
                                                        funcao={handleInputChange}
                                                        additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                        min={isCRBM ? regionalData.minAno2 : undefined}
                                                    />
                                                </td>
                                                <td className="tdNovoDfd">
                                                    <Input
                                                        key={`input-ano3-${item._id}-${inputVersion}`}
                                                        valor={String(regionalData.ano3) || ""}
                                                        nome="ano3"
                                                        tipo="number"
                                                        widthWeb="6vw"
                                                        funcao={handleInputChange}
                                                        additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                        min={isCRBM ? regionalData.minAno3 : undefined}
                                                    />
                                                </td>
                                                <td className="tdNovoDfd">
                                                    <Input
                                                        key={`input-qtdEstimada-${item._id}-${inputVersion}`}
                                                        valor={String(regionalData.qtdEstimada) || ""}
                                                        nome="qtdEstimada"
                                                        tipo="number"
                                                        widthWeb="9vw"
                                                        funcao={handleInputChange}
                                                        additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                        min={isCRBM ? regionalData.minQtdEstimada : undefined}
                                                    />
                                                </td>
                                                <td className="tdNovoDfd">
                                                    <Select
                                                        key={`input-entrega-${item._id}-${inputVersion}`}
                                                        nome="entrega"
                                                        widthWeb="9vw"
                                                        funcao={handleInputChange}
                                                        additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                        valor={regionalData.entrega || ""}
                                                        options={[
                                                            "Janeiro", "Fevereiro", "Março", "Abril", "Maio",
                                                            "Junho", "Julho", "Agosto", "Setembro", "Outubro",
                                                            "Novembro", "Dezembro"
                                                        ]}
                                                    />
                                                </td>
                                                <td className="tdNovoDfd">
                                                    <Input
                                                        key={`input-justificativa-${item._id}-${inputVersion}`}
                                                        valor={regionalData.justificativa || ""}
                                                        nome="justificativa"
                                                        widthWeb="18vw"
                                                        funcao={handleInputChange}
                                                        additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                    />
                                                </td>
                                                <td className="tdNovoDfd">
                                                    <Select
                                                        key={`input-prioridade-${item._id}-${inputVersion}`}
                                                        nome="prioridade"
                                                        widthWeb="9vw"
                                                        funcao={handleInputChange}
                                                        additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                        valor={regionalData.prioridade || ""}
                                                        options={["Baixo", "Médio", "Alto"]}
                                                    />
                                                </td>
                                                <td className="tdNovoDfd" style={{ textAlign: "center" }}>
                                                    <button className="btn-salvar" onClick={() => {
                                                        const itemData = inputData.find(obj => obj.id === item._id)
                                                        if (itemData) sendItem(itemData)
                                                    }} >Salvar</button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>) : (<></>)}
                </div>
                <Select texto="Área" nome="area" valor={area}
                    funcao={updateArea} widthWeb="26vw" widthMobile="80vw"
                    options={["Atendimento Pré-Hospitalar", "Atividades Técnicas", "Combate a Incêndio Florestal",
                        "Combate a Incêndio Urbano", "Materiais de Construção", "Decoração e Eventos", "Educação Física",
                        "Embarcações, Motores e Reboques", "Ensino, Instrução e Pesquisa", "Equipamento de Som e afins",
                        "Ferramentas e Equipamentos Gerais de Busca e Salvamento", "Gabinete Médico e Odontológico",
                        "Gêneros Alimentícios", "Locação de Imóvel", "Materiais de Cozinha", "Material Bélico",
                        "Material de Limpeza", "Mergulho", "Mobiliário, eletrodomésticos e afins", "Obras, serviços de engenharia ou de manutenção predial",
                        "Operações Aquáticas", "Operações Aéreas", "Operações com Cães", "Operações em Altura",
                        "Outras Ferramentas e Equipamentos", "Outros Serviços", "Papelaria e Escritório", "Projetos Sociais",
                        "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra", ,
                        "Seleções internas e Concursos Público",
                        "Tecnologia da Informação", "Uniformes e acessórios", "Viaturas - Locação",
                        "Viaturas - Manutenção", "Viaturas Administrativas - Aquisição", "Viaturas Operacionais - Aquisição"]} />
            </div>
            <SetoresQueEscolheram key={area} area={area} ano={year} />
            {dados.length > 0 ? (<>
                <div style={{ margin: "2vh 0 0 4vw" }}>
                    <Textarea texto="Justificativa" nome="justificativa" widthWeb="90vw"
                        key={area + '-justificativa'} funcao={handleInfoDfd} default={infoDfd.justificativa} />
                    <Textarea texto="Resultados Pretendidos" nome="resultados" widthWeb="90vw"
                        key={area + '-resultados'} funcao={handleInfoDfd} default={infoDfd.resultados} />
                    <Select texto="Vinculação ou dependência com outro objeto?" nome="boolVinculacao"
                        funcao={handleInfoDfd} widthWeb="26vw" widthMobile="80vw"
                        key={area + '-boolVinculacao'} options={["Sim", "Não"]} valor={infoDfd.boolVinculacao} />
                    {infoDfd.boolVinculacao === "Sim" ? (
                        <Textarea nome="vinculacao" widthWeb="90vw"
                            key={area + '-vinculacao'} funcao={handleInfoDfd} default={infoDfd.vinculacao} />
                    ) : null}
                    <Botao texto="Salvar" marginB="1vw"
                        funcao={() => enviar('/api/pca/postInfoDfd', infoDfd)} />
                </div>
                <div className="scroll-tabela">
                    <table className="tabelaNovoDfd">
                        <thead>
                            <tr>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "15vw" }}>OBJETO</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>UNIDADE</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>VALOR ESTIMADO</th>
                                <th className="thNovoDfd" colSpan="3" style={{ width: "20vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>QTD ESTIMADA</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>PREVISÃO DE ENTREGA</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "20vw" }}>JUSTIFICATIVA (se necessário)</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}>GRAU DE PRIORIDADE</th>
                                <th className="thNovoDfd" rowSpan="2" style={{ width: "10vw" }}></th>
                            </tr>
                            <tr>
                                <th className="thNovoDfd">{year - 3}</th>
                                <th className="thNovoDfd">{year - 2}</th>
                                <th className="thNovoDfd">{year - 1}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dados.map((item, indexItem) => {
                                const regionalData = item.regionais[0] || {}
                                console.log(item)
                                return (
                                    <tr key={indexItem}>
                                        <td className="tdNovoDfd">{item.objeto}</td>
                                        <td className="tdNovoDfd">{item.descricao}</td>
                                        <td className="tdNovoDfd">{item.und}</td>
                                        <td className="tdNovoDfd">{formatCurrency(item.valorEstimado)}</td>
                                        <td className="tdNovoDfd">
                                            <Input
                                                key={`input-ano1-${item._id}-${inputVersion}`}
                                                valor={String(regionalData.ano1) || ""}
                                                nome="ano1"
                                                tipo="number"
                                                widthWeb="6vw"
                                                funcao={handleInputChange}
                                                additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                min={isCRBM ? regionalData.minAno1 : undefined}
                                            />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input
                                                key={`input-ano2-${item._id}-${inputVersion}`}
                                                valor={String(regionalData.ano2) || ""}
                                                nome="ano2"
                                                tipo="number"
                                                widthWeb="6vw"
                                                funcao={handleInputChange}
                                                additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                min={isCRBM ? regionalData.minAno2 : undefined}
                                            />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input
                                                key={`input-ano3-${item._id}-${inputVersion}`}
                                                valor={String(regionalData.ano3) || ""}
                                                nome="ano3"
                                                tipo="number"
                                                widthWeb="6vw"
                                                funcao={handleInputChange}
                                                additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                min={isCRBM ? regionalData.minAno3 : undefined}
                                            />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input
                                                key={`input-qtdEstimada-${item._id}-${inputVersion}`}
                                                valor={String(regionalData.qtdEstimada) || ""}
                                                nome="qtdEstimada"
                                                tipo="number"
                                                widthWeb="9vw"
                                                funcao={handleInputChange}
                                                additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                min={isCRBM ? regionalData.minQtdEstimada : undefined}
                                            />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Select
                                                key={`input-entrega-${item._id}-${inputVersion}`}
                                                nome="entrega"
                                                widthWeb="9vw"
                                                funcao={handleInputChange}
                                                additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                valor={regionalData.entrega || ""}
                                                options={[
                                                    "Janeiro", "Fevereiro", "Março", "Abril", "Maio",
                                                    "Junho", "Julho", "Agosto", "Setembro", "Outubro",
                                                    "Novembro", "Dezembro"
                                                ]}
                                            />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Input
                                                key={`input-justificativa-${item._id}-${inputVersion}`}
                                                valor={regionalData.justificativa || ""}
                                                nome="justificativa"
                                                widthWeb="18vw"
                                                funcao={handleInputChange}
                                                additionalArgs={{ id: item._id, indexRegional: 0 }}
                                            />
                                        </td>
                                        <td className="tdNovoDfd">
                                            <Select
                                                key={`input-prioridade-${item._id}-${inputVersion}`}
                                                nome="prioridade"
                                                widthWeb="9vw"
                                                funcao={handleInputChange}
                                                additionalArgs={{ id: item._id, indexRegional: 0 }}
                                                valor={regionalData.prioridade || ""}
                                                options={["Baixo", "Médio", "Alto"]}
                                            />
                                        </td>
                                        <td className="tdNovoDfd" style={{ textAlign: "center" }}>
                                            <button className="btn-salvar" onClick={() => {
                                                const itemData = inputData.find(obj => obj.id === item._id)
                                                if (itemData) sendItem(itemData)
                                            }} >Salvar</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>) : (<></>)}
        </>
    )
}

export default NovoDfd
