const FonteRecursos = ({ fonte, regional, areaEipcp, dfd }) => {
    const acoesObj = {
        "1º CRBM": "4855 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 1º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.102",
        "2º CRBM": "4856 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 2º Comando Regional Bombeiro Militar  / Unidade Financeira  - 23.103",
        "3º CRBM": "4857 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 3º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.104",
        "4º CRBM": "6009 - Manutenção de Serviços Administrativos e Operacionais das Unidades do 4º Comando Regional Bombeiro Militar / Unidade Financeira  - 23.106",
        "Atendimento Pré-Hospitalar": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Atividades Técnicas": "4392 - PREVENÇÃO, FISCALIZAÇÃO E PERÍCIA DE INCÊNDIO - FUNESBOM",
        "Combate a Incêndio Florestal": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Combate a Incêndio Urbano": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Materiais de Construção": "1157 - CONSTRUÇÕES E REFORMAS DE IMÓVEIS DO CBMPB - FUNESBOM",
        "Decoração e Eventos": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Educação Física": "6106 - DESENVOLVIMENTO DE PROGRAMAS E PROJETOS DE RESPONSABILIDADE SOCIAL PARA OS PÚBLICOS INTERNO E EXTERNO - FUNESBOM",
        "Embarcações, Motores e Reboques": "4391- AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS - FUNESBOM",
        "Ensino, Instrução e Pesquisa": "2143 - FORMAÇÃO, ESPECIALIZAÇÃO E CAPACITAÇÃO DE BOMBEIRO MILITAR",
        "Equipamento de Som e afins": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Ferramentas e Equipamentos Gerais de Busca e Salvamento": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Gabinete Médico e Odontológico": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Gêneros Alimentícios": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Locação de Imóvel": "1614 - AQUISICÃO E LOCAÇÃO DE IMÓVEIS PARA O CBMPB - FUNESBOM",
        "Materiais de Cozinha": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Material Bélico": "2217 - AQUISIÇÃO E MANUTENÇÃO DE MATERIAL BELICO - FUNESBOM",
        "Material de Limpeza": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Mergulho": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Mobiliário, eletrodomésticos e afins": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Obras, serviços de engenharia ou de manutenção predial": "1157 - CONSTRUÇÕES E REFORMAS DE IMÓVEIS DO CBMPB - FUNESBOM",
        "Operações Aquáticas": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Operações Aéreas": "2216 - AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE AERONAVE - FUNESBOM",
        "Operações com Cães": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Operações em Altura": "4393 - COMBATE A INCENDIO, SALVAMENTO E ATENDIMENTO PRÉ-HOSPITALAR - FUNESBOM",
        "Outras Ferramentas e Equipamentos": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Outros Serviços": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Papelaria e Escritório": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Projetos Sociais": "6106 - DESENVOLVIMENTO DE PROGRAMAS E PROJETOS DE RESPONSABILIDADE SOCIAL PARA OS PÚBLICOS INTERNO E EXTERNO - FUNESBOM",
        "Serviços terceirizados com ou sem regime de dedicação exclusiva de mão de obra": "4539 - Formação e Capacitação de Oficiais e Praças / Unidade Financeira  - 23.000",
        "Seleções internas e Concursos Público": fonte === "CBM" ? "4539 - Formação e Capacitação de Oficiais e Praças / Unidade Financeira  - 23.000" : "2143 - FORMAÇÃO, ESPECIALIZAÇÃO E CAPACITAÇÃO DE BOMBEIRO MILITAR",
        "Tecnologia da Informação": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Uniformes e acessórios": "4216 - MANUTENÇAO DE SERVICOS ADMINISTRATIVOS",
        "Viaturas - Locação": "4391- AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS - FUNESBOM",
        "Viaturas - Manutenção": "4391- AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS - FUNESBOM",
        "Viaturas Administrativas - Aquisição": "4391- AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS - FUNESBOM",
        "Viaturas Operacionais - Aquisição": "4391- AQUISIÇÃO, LOCAÇÃO E MANUTENÇÃO DE VIATURAS, PEÇAS, ACESSÓRIOS E SERVIÇOS - FUNESBOM",
    }

    let programa = "5005 - Paraíba mais Segura";
    let fonteRecursos = "759 - FUNESBOM";
    let ug = "23901 - Fundo Especial do Corpo de Bombeiros";

    if (fonte === "CBM" && regional === "Núcleo Estratégico") {
        ug = "23101 - Comando Geral do Corpo de Bombeiros Militar";
        fonteRecursos = "753 - Taxas de Concursos e Seleções Internas";
    } else if (fonte === "CBM") {
        ug = "23101 - Comando Geral do Corpo de Bombeiros Militar";
        fonteRecursos = "500 - Recursos do Tesouro Estadual";
    }

    if (fonte === "CBM" && dfd !== "geral") {
        programa = "5046 - Programa de Gestão e Manutenção e Serviços ao Estado";
    }

    // Garantir que `areaEipcp` seja sempre um array válido
    const areas = Array.isArray(areaEipcp) ? areaEipcp : [];

    // Obtém as ações correspondentes às áreas
    let acoes = areas
        .map(area => acoesObj[area]) // Mapeia `areaEipcp` para `acoesObj`
        .filter(Boolean); // Remove valores `undefined`

    if (fonte === "CBM" && dfd !== "geral") {
        acoes = [acoesObj[regional] || "Ação não encontrada"];
    }

    if (fonte === "CBM" && regional === "Núcleo Estratégico" && dfd !== "geral") {
        acoes = ["4539 - Formação e Capacitação de Oficiais e Praças"];
    }

    return (
        <>
            <div style={{ borderBottom: "1px solid black", textAlign: "justify", marginTop: "5px", fontWeight: "320" }}>
                <b>
                    <div style={{ textAlign: "center" }}>
                        <b>Fonte de Créditos Orçamentários sugerida para execução:</b>
                    </div>
                    <br />
                    <div style={{ display: "flex", alignItems: "center", borderTop: "1px solid black" }}>
                        <div style={{
                            width: "25vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                            display: "inline-block"
                        }}>
                            <b>Código da Unidade Gestora (UG)</b>
                        </div>
                        <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                            {ug}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", borderTop: "1px solid black" }}>
                        <div style={{
                            width: "25vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                            display: "inline-block"
                        }}>
                            <b>Fonte de Recursos</b>
                        </div>
                        <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                            {fonteRecursos}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", borderTop: "1px solid black" }}>
                        <div style={{
                            width: "25vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                            display: "inline-block"
                        }}>
                            <b>Programa de Trabalho</b>
                        </div>
                        <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                            {programa}
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", borderTop: "1px solid black" }}>
                        <div style={{
                            width: "25vw", padding: "5px 0 5px 5px",
                            display: "inline-block"
                        }}>
                            <b>Ações</b>
                        </div>
                        <div style={{ padding: "5px 0 5px 5px", display: "inline-block", borderLeft: "1px solid black" }}>
                            {acoes.length > 0
                                ? [...new Set(acoes)].map((acao, index) => ( 
                                    <div key={index}>{acao}</div>
                                ))
                                : "Nenhuma ação encontrada"}
                        </div>
                    </div>
                </b>
            </div>
        </>
    );
};

export default FonteRecursos;
