import { useState } from 'react'
import axios from 'axios'

import Input from '../Biblioteca/Input'
import Botao from '../Biblioteca/Botao'
import Select from '../Biblioteca/Select'

const Previas = () => {
    const [dados, setDados] = useState({})

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    const enviar = () => {
        axios.post("/api/whatsapp/previas", dados)
            .then(res => {
                alert(res.data)
                window.location = "/previas"
            })
            .catch(error => alert(error.response.data.message))
    }
    return (
        <>
            <div style={{ margin: "5vh 10vw" }}>
                <Input texto="Matrícula" nome="matricula"
                    funcao={(e) => updateField(e)}
                    widthWeb="26vw" widthMobile="80vw" />
                <Input texto="Celular" nome="telefone" tipo="number"
                    funcao={(e) => updateField(e)}
                    widthWeb="26vw" widthMobile="80vw" />
                <Select texto="Evento" nome="evento"
                    funcao={(e) => updateField(e)} widthWeb="26vw" widthMobile="80vw"
                    options={["BEL MARQUES - 14/02", "ELBA RAMALHO - 21/02", "BLOCO DOS ATLETAS - 22/02",
                        "VIRGENS - 23/02", "MURIÇOCAS - 26/02"
                    ]} />
                <Botao texto="Enviar" funcao={enviar} widthMobile="40vw" />
            </div>
        </>
    )
}

export default Previas