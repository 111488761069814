import { useEffect, useState } from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'
import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'
import Textarea from '../../Biblioteca/Textarea'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const EscolhaItens = ({ year }) => {
    const [dados, setDados] = useState({})
    const [search, setSearch] = useState()
    const [searchResultsRemocao, setSearchResultsRemocao] = useState([])
    const [itensEdicao, setItensEdicao] = useState([])
    const [searchResultsAdicao, setSearchResultsAdicao] = useState([])
    const [diferencaValor, setDiferencaValor] = useState(0)
    const [infoDfd, setInfoDfd] = useState({})
    const [disabled, setDisabled] = useState(false)

    const updateField = (e) => {
        const { value, name } = e.target
        const tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
        setSearchResultsRemocao([])
        setItensEdicao([])
        setSearchResultsAdicao([])
        setDiferencaValor(0)
    }

    const updateSearch = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setSearch(tempState)
    }

    const handleInfoDfd = (e) => {
        const { value, name } = e.target
        const tempState = { ...infoDfd }
        tempState[name] = value
        setInfoDfd(tempState)
    }

    const buscaItemRemocao = () => {
        if (!dados.fonte || !dados.anoPreenchimentoPca) {
            return alert("É necessário definir uma Regional, Fonte e Ano de Preenchimento para realizar buscas e edições!")
        }
        axios.post('/api/pca/buscarItemRemocao', search)
            .then(res => setSearchResultsRemocao(res.data))
            .catch(error => {
                alert(error.response.data)
                setSearchResultsRemocao([])
            })
    }

    const addItensEdicao = (item, quantidade = 0, indexRegional, tipo) => {
        const regionalAtual = item.regionais[indexRegional]

        if (itensEdicao.some(el =>
            el._id === item._id &&
            el.regionais.some(reg =>
                reg.fonte === regionalAtual.fonte &&
                reg.anoPreenchimentoPca === regionalAtual.anoPreenchimentoPca &&
                reg.regional === regionalAtual.regional
            )
        )) {
            return alert("O objeto já foi incluído para remoção.")
        }
        const novoItem = {
            ...item,
            regionais: [regionalAtual],
            quantidade,
            indexRegional,
            tipo: tipo
        }
        setItensEdicao(prevState => [...prevState, novoItem])
        setSearchResultsRemocao([])
        setSearchResultsAdicao([])
    }

    const buscaItemAdicao = () => {
        if (!dados.fonte || !dados.anoPreenchimentoPca) {
            return alert("É necessário definir uma Regional, Fonte e Ano de Preenchimento para realizar buscas e edições!")
        }
        axios.post('/api/pca/buscarItem', search)
            .then(res => setSearchResultsAdicao(res.data))
            .catch(error => {
                alert(error.response.data)
                setSearchResultsAdicao([])
            })
    }

    const updateItemQuantidade = (index, e) => {
        const { name, value } = e.target
        setItensEdicao(prevState => {
            const newState = [...prevState]
            const item = { ...newState[index] }

            if (name === "qtdEstimada") {
                const novaQuantidade = parseInt(value) || 0
                if (novaQuantidade < 0) return prevState
                const diferenca = (item.quantidade - novaQuantidade) * item.valorEstimado
                item.quantidade = novaQuantidade
                setDiferencaValor(prev => prev + diferenca)
            } else if (name === "ano1" || name === "ano2" || name === "ano3") {
                item[name] = parseInt(value) || 0
            } else {
                item[name] = value
            }

            newState[index] = item
            return newState
        })
    }

    const editarPca = () => {
        setDisabled(true)
        axios.post('/api/dfdExtra/novoDfdExtra', { itens: itensEdicao, dados: dados, infoDfd: infoDfd })
            .then(res => {
                alert(res.data)
                window.location = "/GetDfdExtra"
            })
            .catch(error => {
                alert(error.response.data.message)
                setDisabled(false)
            })
    }

    return (
        <>
            <div style={{ margin: "4vh 0 4vh 2vw" }}>
                <Select texto="Fonte" nome="fonte"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                    options={["CBM", "FUNESBOM"]} />
                <Select texto="Ano do Preenchimento do PCA" nome="anoPreenchimentoPca"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                    options={[2024, 2025]} />
            </div>
            <div style={{ margin: "4vh 0 4vh 2vw" }}>
                <Input texto="Processo(PBDOC)" nome="pbdoc" widthWeb="30vw" widthMobile="80vw" funcao={updateField} />
            </div>
            <div style={{ margin: "2vh 0 0 2vw" }}>
                <Textarea texto="Justificativa da não inclusão no PCA e Resultados Pretendidos" nome="justificativaInclusao" widthWeb="90vw"
                    funcao={handleInfoDfd} />
                <Textarea texto="Justificativa da remoção dos itens (se necessário)" nome="justificativaRemocao" widthWeb="90vw"
                    funcao={handleInfoDfd} />
            </div>
            <div style={{ textAlign: "center", color: "rgb(51, 52, 143)", fontSize: "1.1rem" }}><b>ITENS PARA REMOÇÃO</b></div>
            <div style={{ marginLeft: "2vw", display: "inline-block" }}>
                <Input texto="Buscar Item" nome="itemRemocao" widthWeb="30vw" widthMobile="80vw" funcao={updateSearch} />
                <Botao texto="BUSCAR" funcao={buscaItemRemocao}
                    align="left" widthMobile="40vw" />
            </div>
            {searchResultsRemocao.length > 0 ? (<>
                <div className="scroll-tabela" style={{ width: "90vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <tr>
                            <th className="thNovoDfd" style={{ width: "15vw" }}>OBJETO</th>
                            <th className="thNovoDfd" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                            <th className="thNovoDfd">VALOR ESTIMADO</th>
                            <th className="thNovoDfd">SETOR/REGIONAL</th>
                            <th className="thNovoDfd">QUANTIDADE</th>
                            <th className="thNovoDfd">TOTAL(R$)</th>
                            <th className="thNovoDfd">ÁREA</th>
                            <th className="thNovoDfd">ADICIONAR PARA REMOÇÃO</th>
                        </tr>
                        <tbody>
                            {searchResultsRemocao.map((el, index) => {
                                return el.regionais.map((regional, idx) => {
                                    if (regional.fonte === dados.fonte && regional.anoPreenchimentoPca === parseInt(dados.anoPreenchimentoPca) && regional.qtdEstimada !== 0) {
                                        const valorTotal = el.valorEstimado * regional.qtdEstimada
                                        const descricao = el.descricao.length > 200 ? el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                        return (
                                            <tr key={`${index}-${idx}`}>
                                                <td className="tdNovoDfd">{el.objeto}</td>
                                                <td className="tdNovoDfd">{descricao}</td>
                                                <td className="tdNovoDfd">{formatCurrency(el.valorEstimado)}</td>
                                                <td className="tdNovoDfd">{regional.regional}</td>
                                                <td className="tdNovoDfd">{regional.qtdEstimada}</td>
                                                <td className="tdNovoDfd">{formatCurrency(valorTotal)}</td>
                                                <td className="tdNovoDfd">{el.areaEipcp}</td>
                                                <td className="tdNovoDfd">
                                                    <Botao texto="Adicionar" funcao={() => addItensEdicao(el, regional.qtdEstimada, idx, "remoção")} widthWeb="6vw" cor="#dc3545" />
                                                </td>
                                            </tr>
                                        )
                                    }
                                    return null
                                })
                            })}
                        </tbody>
                    </table>
                </div>
            </>) : (<></>)}
            <hr></hr>

            <div style={{ textAlign: "center", color: "rgb(51, 52, 143)", fontSize: "1.1rem" }}><b>ITENS PARA ADIÇÃO</b></div>
            <div style={{ marginLeft: "2vw", display: "inline-block" }}>
                <Input texto="Buscar Item" nome="item" widthWeb="30vw" widthMobile="80vw" funcao={updateSearch} />
                <Botao texto="BUSCAR" funcao={buscaItemAdicao}
                    align="left" widthMobile="40vw" />
            </div>
            {searchResultsAdicao.length > 0 ? (<>
                <div className="scroll-tabela" style={{ width: "90vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <tr>
                            <th className="thNovoDfd" style={{ width: "15vw" }}>OBJETO</th>
                            <th className="thNovoDfd" style={{ width: "25vw" }}>DESCRIÇÃO</th>
                            <th className="thNovoDfd">VALOR ESTIMADO</th>
                            <th className="thNovoDfd">ÁREA</th>
                            <th className="thNovoDfd">ADICIONAR PARA INCLUSÃO</th>
                        </tr>
                        <tbody>
                            {searchResultsAdicao.map((el, index) => {
                                const descricao = el.descricao.length > 200 ?
                                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                return (
                                    <tr key={index}>
                                        <td className="tdNovoDfd">{el.objeto}</td>
                                        <td className="tdNovoDfd">{descricao}</td>
                                        <td className="tdNovoDfd">{formatCurrency(el.valorEstimado)}</td>
                                        <td className="tdNovoDfd">{el.areaEipcp}</td>
                                        <td className="tdNovoDfd">
                                            <Botao texto="Adicionar" funcao={() => addItensEdicao(el)}
                                                widthWeb="6vw" />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>) : (<></>)}
            <hr />
            <div style={{ textAlign: "center", color: "rgb(51, 52, 143)", fontSize: "1.1rem" }}><b>EDIÇÃO DO PCA</b></div>
            {itensEdicao.length > 0 ? (<>
                <div className="scroll-tabela" style={{ width: "98vw", margin: "2vh auto 2vh auto" }}>
                    <table className="tabelaNovoDfd">
                        <tr>
                            <th className="thDfdGeral" style={{ width: "15vw" }}>OBJETO</th>
                            <th className="thDfdGeral" style={{ width: "15vw" }}>DESCRIÇÃO</th>
                            <th className="thDfdGeral">VALOR ESTIMADO</th>
                            <th className="thDfdGeral">SETOR/REGIONAL</th>
                            <th className="thDfdGeral" style={{ width: "5vw" }}>{year - 3}</th>
                            <th className="thDfdGeral" style={{ width: "5vw" }}>{year - 2}</th>
                            <th className="thDfdGeral" style={{ width: "5vw" }}>{year - 1}</th>
                            <th className="thDfdGeral">QUANTIDADE</th>
                            <th className="thDfdGeral">PREVISÃO DE ENTREGA</th>
                            <th className="thDfdGeral">JUSTIFICATIVA</th>
                            <th className="thDfdGeral">GRAU DE PRIORIDADE</th>
                            <th className="thDfdGeral">ÁREA</th>
                        </tr>
                        <tbody>
                            {itensEdicao.map((el, index) => {
                                const descricao = el.descricao.length > 200 ?
                                    el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
                                console.log(el)
                                return (
                                    <tr key={index}>
                                        <td className="tdDfdGeral">{el.objeto}</td>
                                        <td className="tdDfdGeral">{descricao}</td>
                                        <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                                        <td className="tdDfdGeral">{el.regionais[0]?.regional || "Sem Registro"}</td>
                                        <td className="tdDfdGeral">
                                            {el.tipo !== "remoção" ? (
                                                <Input nome="ano1" tipo="number" valor={el.regionais[0]?.ano1.toString() || 0}
                                                    widthWeb="100%" funcao={(e) => updateItemQuantidade(index, e)} />
                                            ) : (<>{el.regionais[0]?.ano1.toString()}</>)}
                                        </td>
                                        <td className="tdDfdGeral">
                                            {el.tipo !== "remoção" ? (
                                                <Input nome="ano2" tipo="number" valor={el.regionais[0]?.ano2.toString() || 0}
                                                    widthWeb="100%" funcao={(e) => updateItemQuantidade(index, e)} />
                                            ) : (<>{el.regionais[0]?.ano2.toString()}</>)}
                                        </td>
                                        <td className="tdDfdGeral">
                                            {el.tipo !== "remoção" ? (
                                                <Input nome="ano3" tipo="number" valor={el.regionais[0]?.ano3.toString() || 0}
                                                    widthWeb="100%" funcao={(e) => updateItemQuantidade(index, e)} />
                                            ) : (<>{el.regionais[0]?.ano3.toString()}</>)}
                                        </td>
                                        <td className="tdDfdGeral">
                                            <Input nome="qtdEstimada" tipo="number" valor={el.quantidade}
                                                widthWeb="100%" funcao={(e) => updateItemQuantidade(index, e)} />
                                        </td>
                                        <td className="tdDfdGeral">
                                            {el.tipo !== "remoção" ? (
                                                <Select nome="entrega" funcao={(e) => updateItemQuantidade(index, e)}
                                                    valor={el.entrega} options={["Janeiro", "Fevereiro", "Março", "Abril", "Maio",
                                                        "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]}
                                                    widthWeb="100%" />
                                            ) : (<>{el.regionais[0]?.entrega}</>)}
                                        </td>
                                        <td className="tdDfdGeral">
                                            {el.tipo !== "remoção" ? (
                                                <Input nome="justificativa" valor={el.justificativa}
                                                    widthWeb="100%" funcao={(e) => updateItemQuantidade(index, e)} />
                                            ) : (<>{el.regionais[0]?.justificativa.slice(0, 50) + "..."}</>)}
                                        </td>
                                        <td className="tdDfdGeral">
                                            {el.tipo !== "remoção" ? (
                                                <Select nome="prioridade" funcao={(e) => updateItemQuantidade(index, e)}
                                                    valor={el.prioridade} options={["Baixo", "Médio", "Alto"]} widthWeb="100%" />
                                            ) : (<>{el.regionais[0]?.prioridade}</>)}
                                        </td>
                                        {/* <td className="tdDfdGeral">{formatCurrency(el.quantidade * el.valorEstimado)}</td> */}
                                        <td className="tdDfdGeral">{el.areaEipcp}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div>
                        <Botao texto="Salvar" funcao={() => editarPca()}
                            widthWeb="6vw" disabled={disabled} />
                    </div>
                    <div style={{
                        margin: "2vh auto",
                        padding: "1vh 2vw",
                        width: "90vw",
                        textAlign: "center",
                        backgroundColor: "#f8f9fa",
                        border: "1px solid #dee2e6",
                        borderRadius: "0.25rem",
                        color: diferencaValor < 0 ? "red" : "green"
                    }}>
                        <strong>Diferença de Valor: {formatCurrency(diferencaValor)}</strong>
                    </div>
                </div>
            </>) : (<></>)}

        </>
    )
}

export default EscolhaItens