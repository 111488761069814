import { useState, useEffect } from 'react'
import axios from 'axios'

import brasao from '../../../assets/brasao.png'
import "./PrintDfdSetorial.css"
import FonteRecursos from './FonteRecursos'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'
import CaixaFinalDfd from './CaixaFinalDfd'
import CaixaEdicoes from './CaixaEdicoes'

const PrintDfdGeral = (anoPreenchimento) => {
    const [tabelas, setTabelas] = useState([])
    const [fonte, setFonte] = useState("")
    const [edicoes, setEdicoes] = useState([])
    const [cmd, setCmd] = useState()
    let numeroItem = 1

    useEffect(() => {
        if (anoPreenchimento.ano === "2023") var url = '/api/pca/getPca2023Atualizado'
        else var url = '/api/pca/getDfdGeral'
        axios.post(url, { fonte: anoPreenchimento.fonte, anoPreenchimento: anoPreenchimento.ano })
            .then(res => {
                setTabelas(res.data.tabelas)
                setFonte(res.data.fonte)
                if (res.data.edicoes) setEdicoes(res.data.edicoes)
            })
            .catch(error => alert(error.response.data.message))
    }, [])
    
    useEffect(() => {
        const url = '/api/orcamento/getCmdAss'
        axios.post(url, { regional: localStorage.regionalUser })
            .then(res => setCmd(res.data))
            .catch(error => alert(error.response.data.message))
    }, [])
    console.log(cmd)
    return (
        <div>
            <div style={{ textAlign: "center", marginTop: "4vh", fontWeight: "600", marginBottom: "5vh" }}>
                <img src={brasao} style={{ width: "8vw" }} />
                <div>ESTADO DA PARAÍBA</div>
                <div>SECRETARIA DE SEGURANÇA E DEFESA SOCIAL</div>
                <div>CORPO DE BOMBEIROS MILITAR DA PARAÍBA</div>
            </div>
            <div style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", marginBottom: "4vh" }}>
                DOCUMENTO DE FORMALIZAÇÃO DE DEMANDA – DFD (CONSOLIDADO)
            </div>
            <div style={{
                border: "1px solid black", width: "98vw", fontSize: "12px",
                marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
            }}>
                <div style={{
                    borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                    textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                }}>
                    INFORMAÇÕES DO NÍVEL SETORIAL
                </div>
                <div style={{
                    borderBottom: "1px solid black",
                    padding: "5px 0 5px 5px"
                }}><b>NÍVEL SETORIAL: </b>{RenderNivelSetorial()}</div>
                <div style={{ borderBottom: "1px solid black", display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>Bombeiro Militar  reponsável pelo Planejamento e esclarecimentos da Demanda: </b>
                        {localStorage.nome}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Matrícula CBMPB: </b> {localStorage.numeroMatricula}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{
                        width: "45vw", borderRight: "1px solid black", padding: "5px 0 5px 5px",
                        display: "inline-block"
                    }}>
                        <b>E-mail: </b> {localStorage.email}
                    </div>
                    <div style={{ padding: "5px 0 5px 5px", display: "inline-block" }}>
                        <b>Contato: </b> {localStorage.contato}
                    </div>
                </div>
            </div>
            {RenderTabelas()}
            {localStorage.tipo === "regional" ?
                (<CaixaFinalDfd fonte={fonte} cmd={cmd} regional={localStorage.regionalUser} />) :
                (
                    <>
                        <div style={{
                            border: "1px solid black", width: "98vw", fontSize: "12px",
                            marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                        }}>
                            <div style={{
                                borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                                textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                            }}>
                                RESPONSABILIDADE PELA FORMALIZAÇÃO DA DEMANDA E CONTEÚDO DO DOCUMENTO
                            </div>
                            <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                                Certifico que as formalizações das demandas compiladas no presente documento se fazem
                                necessárias pelos motivos expostos nas justificativas para cada área do presente documento,
                                bem como nos DFDs em anexo, tudo em conformidade com o inciso VII do Art. 12 da
                                Lei nº 14.133/2021 - "VII - a partir de documentos de formalização de demandas, os órgãos
                                responsáveis pelo planejamento de cada ente federativo poderão, na forma de regulamento,
                                elaborar plano de contratações anual, com o objetivo de racionalizar as contratações dos órgãos
                                e entidades sob sua competência, garantir o alinhamento com o seu planejamento estratégico e
                                subsidiar a elaboração das respectivas leis orçamentárias.",
                                bem como em atendimento ao DECRETO Nº 44.639, de 22 de dezembro de 2023.</div>
                            <br />
                            <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                                João Pessoa, datado via PBDOC.
                            </div><br />
                            <div style={{ textAlign: "center", marginTop: "8vh" }}>
                                <br />
                                <b>LUCAS</b> SEVERIANO DE LIMA MEDEIROS - CEL QOBM<br />
                                521.264-2 - Presidente da EIPCP
                                <br />
                                Responsável pelo Planejamento
                                <br />
                                Assinado e datado via PBDOC
                            </div>
                        </div>
                        <div style={{
                            border: "1px solid black", width: "98vw", fontSize: "12px",
                            marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                        }}>
                            <div style={{
                                borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                                textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                            }}>
                                ANUÊNCIA DO ORDENADOR DE DESPESAS
                            </div>
                            <div style={{ textAlign: "justify", padding: "5px", fontWeight: "320" }}><br />
                                <b style={{ fontWeight: "600" }}>RATIFICO</b> as demandas e os quantitativos solicitados,
                                bem como <b style={{ fontWeight: "600" }}>ATESTO</b> que o PCA/FUNESBOM/2024
                                proposto para execução no ano de 2025 está em conformidade com o planejamento, elaboração e
                                execução das peças orçamentárias aplicáveis e com o planejamento estratégico 2024/2027 do
                                Corpo de Bombeiros Militar da Paraíba.
                            </div><br />
                            <div style={{ textAlign: "right", padding: "5px", fontWeight: "320" }}><br />
                                João Pessoa, datado via PBDOC.
                            </div><br />
                            <div style={{ textAlign: "center", padding: "5px", fontWeight: "320" }}><br />
                                MARCELO AUGUSTO DE <b style={{ fontWeight: "600" }}>ARAÚJO</b> BEZERRA - CEL QOBM <br />
                                Comandante Geral do CBMPB/Ordenador de Despesa<br />
                                MAT. 516.508-3<br />
                                Assinado e datado via PBDOC
                            </div><br />
                        </div>
                        {edicoes.length > 0 ? (<CaixaEdicoes edicoes={edicoes} />) : (<></>)}
                    </>
                )}
        </div >
    )

    function RenderNivelSetorial() {
        if (localStorage.regionalUser === "1º CRBM" || localStorage.regionalUser === "2º CRBM" ||
            localStorage.regionalUser === "3º CRBM" || localStorage.regionalUser === "4º CRBM" ||
            localStorage.regionalUser === "5º CRBM" || localStorage.regionalUser === "CBMPB") {
            return localStorage.regionalUser
        } else return `Departamento (${localStorage.regionalUser})`
    }

    function RenderTabelas() {
        let dfds = []
        if (tabelas !== undefined) {
            Object.keys(tabelas).map(obj => {
                var titulo = obj
                if (obj !== "null") {
                    dfds.push(
                        <div style={{
                            border: "1px solid black", width: "98vw", fontSize: "12px",
                            marginLeft: "auto", marginRight: "auto", marginBottom: "4vh"
                        }}>
                            <div style={{
                                borderBottom: "1px solid black", backgroundColor: "#f9ff06", fontSize: "16px",
                                textAlign: "center", padding: "5px 0 5px 0", fontWeight: "600",
                            }}>
                                INFORMAÇÕES DA NECESSIDADE DE CONTRATAÇÃO ({titulo})
                            </div>
                            <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                                <div style={{ textAlign: "center" }}><b>Descrição sucinta da solicitação:</b></div>
                                <br />
                                Formalização da Demanda para fins de Planejamento de Contratação Anual {parseInt(anoPreenchimento.ano)},
                                exercício financeiro {parseInt(anoPreenchimento.ano) + 1}, de objetos e serviços comuns para desenvolvimento das ações
                                e ativividades fins e meio no âmbito da corporação.
                            </b></div>
                            <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                                <div style={{ textAlign: "center" }}><b>Necessidade / Justificativa técnica da contratação para itens:</b></div>
                                <br />
                                {tabelas[obj].justificativa}
                            </b></div>
                            <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                                <div style={{ textAlign: "center" }}><b>Resultados Pretendidos:</b></div>
                                <br />
                                {tabelas[obj].resultados}
                            </b></div>
                            {tabelas[obj].boolVinculacao === "Sim" ? <>
                                <div style={{ borderBottom: "1px solid black", textAlign: "justify", padding: "5px", fontWeight: "320" }}><b>
                                    <div style={{ textAlign: "center" }}><b>Vinculação:</b></div>
                                    <br />
                                    {tabelas[obj].vinculacao}
                                </b></div>
                            </> : <></>}
                            <FonteRecursos fonte={fonte}
                                regional={localStorage.regionalUser}
                                areaEipcp={obj}
                                dfd={"geral"} />
                            <table className="tableDfd">
                                <thead>
                                    <tr>
                                        <th rowSpan="2" className="thDfdGeral" style={{ width: "3vw" }}>ITEM</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{ width: "4vw" }}>CÓDIGO</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{ width: "8vw" }}>OBJETO</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{
                                            width: anoPreenchimento.ano !== "2023" ? "14vw" : "20vw"
                                        }}>DESCRIÇÃO BASE DE REFERÊNCIA</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>UND. DE MEDIDA</th>
                                        <th className="thDfdGeral" colSpan="3" style={{ width: "9vw" }}>QUANTIDADE ESTIMADA NOS ÚLTIMOS 3 ANOS</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{ width: "5vw" }}>QTD. ESTIMADA</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{
                                            width: anoPreenchimento.ano !== "2023" ? "17vw" : "26vw"
                                        }}>JUSTIFICATIVA DOS QUANTITATIVOS</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>VALOR ESTIMADO UNITÁRIO</th>
                                        <th rowSpan="2" className="thDfdGeral" style={{ width: "10vw" }}>COMPROMETIMENTO ORÇAMENTÁRIO LOA 2025</th>
                                        {anoPreenchimento.ano !== "2023" ? (<>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "9vw" }}>DATA PRETENDIDA / CALENDÁRIO DE CONTRATAÇÃO</th>
                                            <th rowSpan="2" className="thDfdGeral" style={{ width: "6vw" }}>GRAU DE PRIORIDADE</th>
                                        </>) : (<></>)}
                                    </tr>
                                    <tr>
                                        <th className="thDfdGeral">2021</th>
                                        <th className="thDfdGeral">2022</th>
                                        <th className="thDfdGeral">2023</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {RenderRows(tabelas[obj].items)}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th colSpan="10" className="thDfdGeral" style={{
                                            backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                        }}>
                                            VALOR TOTAL DO DOCUMENTO DE FORMALIZAÇÃO DA DEMANDA
                                        </th>
                                        <th colSpan="4" className="thDfdGeral" style={{
                                            backgroundColor: "#f9ff06", color: "black", textAlign: "left"
                                        }}>
                                            {RenderSomaTotal(tabelas[obj].items, obj)}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    )
                }
            })
        }
        return dfds
    }

    function RenderSomaTotal(array, obj) {
        let somaTotal = 0
        array.map(el => somaTotal = somaTotal + (el.totalQtdEstimada * el.valorEstimado))
        return formatCurrency(somaTotal)
    }

    function RenderRows(array) {
        let rows = []
        array.map((el, index) => {
            const descricao = el.descricao.length > 200 ?
                el.descricao.slice(0, 200) + "... (Ver tabela básica SGC)" : el.descricao
            const media = (el.totalAno1 + el.totalAno2 + el.totalAno3) / 3
            var justificativa
            if (media === 0) justificativa = "Item que não teve compra nos últimos três anos, mas apresentaram demandas de acordo com as quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo"
            else if (media <= el.totalQtdEstimada) justificativa = "Quantidades referentes ao somatório dos DFDs Setoriais dos setores listados na justificativa da área conforme todas as JUSTIFICATIVAS DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
            else justificativa = "Conforme JUSTIFICATIVA DOS QUANTITATIVOS constantes nos DFDs Setoriais em anexo."
            if (el.totalQtdEstimada > 0) {
                rows.push(
                    <tr key={`objeto${index}`}>
                        {/* <td className="tdDfdGeral">{numeroItem}</td> */}
                        <td className="tdDfdGeral">{el.indexPCA}</td>
                        <td className="tdDfdGeral">{el?.codCentral || "Sem correspondência no SGC"}</td>
                        <td className="tdDfdGeral">{el.objeto}</td>
                        <td className="tdDfdGeral">{descricao}</td>
                        <td className="tdDfdGeral">{el.und}</td>
                        <td className="tdDfdGeral">{el.totalAno1}</td>
                        <td className="tdDfdGeral">{el.totalAno2}</td>
                        <td className="tdDfdGeral">{el.totalAno3}</td>
                        <td className="tdDfdGeral">{el.totalQtdEstimada}</td>
                        <td className="tdDfdGeral">
                            {justificativa}
                            <br /><br />
                            Setores que solicitaram o item: <br />
                            {el.regionais.map((item, index) => {
                                if (index !== 0) return `, ${item.regional}(${item.qtdEstimada})`
                                else return `${item.regional}(${item.qtdEstimada})`
                            })}
                        </td>
                        <td className="tdDfdGeral">{formatCurrency(el.valorEstimado)}</td>
                        <td className="tdDfdGeral">{formatCurrency(el.totalQtdEstimada * el.valorEstimado)}</td>
                        {anoPreenchimento.ano !== "2023" ? (<>
                            <td className="tdDfdGeral">{el.closestMonth}</td>
                            <td className="tdDfdGeral">{el.highestPriority}</td>
                        </>) : (<></>)}
                    </tr>
                )
                numeroItem++
            }
        }
            // else console.log(el.objeto, (el.valorEstimado))
        )
        return rows
    }
}

export default PrintDfdGeral