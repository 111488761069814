import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'
import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'
import converterData from '../../Biblioteca/Funcoes/converterData'

const mediaWeb = window.matchMedia("(min-width:600px)").matches

const optionsFiltro = {
    "": [],
    "PROGRAMA": [5046, 5005],
    "AÇÃO": [
        4217, 4221, 4855, 4856, 4216, 6009, 4857, 4539, 4393, 4392, 4211,
        2143, 1614, 4391, 2216, 1157, 6106, 4938
    ],
    "NATUREZA": [
        319011, 319012, 339046, 319013, 319113, 339030, 339015, 339039,
        449052, 339014, 339139, 449051, 449039, 339047
    ],
    "FINALIDADE": [
        100003.0, 100019.0, 100001.0, 100015.0, 300023.0, 100008.0, 100002.0,
        100013.0, 100017.0, 150001.0, 300001.0, 100021.0, 300002.0, 400002.0
    ],
    "TIPO NE": ["PRINCIPAL", "ANULAÇÃO TOTAL", "SUPLEMENTAÇÃO", "ANULAÇÃO PARCIAL"],
    "GRUPO FINANCEIRO": [
        "150000 - Pessoal e Encargos Sociais-150000",
        "350000 - Outras Despesas Correntes-350000",
        "375300 - Outras Despesas Correntes-375300",
        "450000 - Investimentos-450000",
        "375900 - Outras Despesas Correntes-375900",
        "475900 - Investimentos-475900"
    ],
    "ITEM DA DESPESA": [
        "10 - DECIMO TERCEIRO SALARIO", "5 - DECIMO TERCEIRO SALARIO", "17 - ADIANTAMENTOS DA FOLHA",
        "1 - SOLDO", "1 - AUXILIO ALIMENTACAO", "99 - OUTRAS DESPESAS FIXAS PESSOAL MILITAR",
        "2 - CONTRIBUICOES AO INSS", "6 - CONTRIBUICOES DA PBPREV", "36 - MATERIAL HOSPITALAR",
        "1 - DIARIAS NO ESTADO", "44 - MATERIAL DE SINALIZACAO VISUAL E AFINS",
        "7 - GENEROS DE ALIMENTACAO", "2 - DIARIAS FORA DO ESTADO", "49 - SERVICOS DE APOIO AO ENSINO",
        "16 - MATERIAL DE EXPEDIENTE", "1 - VENCIMENTOS", "20 - BOLSA DESEMPENHO",
        "3 - COMBUSTIVEIS E LUBRIFICANTES PARA OUTRAS FINALIDADES", "11 - MATERIAL QUIMICO",
        "22 - MATERIAL DE LIMPEZA E PRODUCAO DE HIGIENIZACAO", "52 - OUTROS MATERIAIS DE CONSUMO",
        "24 - MATERIAL PARA MANUTENCAO DE BENS IMOVEIS", "15 - MANUTENCAO E CONSERVACAO DE MAQUINAS E EQUIPAMENTOS",
        "19 - EQUIPAMENTOS DE PROCESSAMENTO DE DADOS", "28 - MATERIAL DE PROTECAO E SEGURANCA",
        "14 - MANUTENCAO E CONSERVACAO. DE BENS IMOVEIS", "21 - MATERIAL DE COPA E COZINHA",
        "17 - MANUTENCAO E CONSERVACAO DE BENS MOVEIS DE OUTRAS NATUREZAS",
        "4 - GAS ENGARRAFADO E OUTROS MATERIAIS ENGARRAFADOS", "23 - UNIFORMES, TECIDOS E AVIAMENTOS",
        "25 - MATERIAL PARA MANUTENCAO DE BENS MOVEIS", "39 - OUTROS MATERIAIS PERMANENTES",
        "41 - MATERIAL PARA UTILIZACAO EM GRAFICA", "18 - MAQUINAS, UTENSILIOS E EQUIPAMENTOS DIVERSOS",
        "26 - MATERIAL ELETRICO E ELETRONICO", "61 - SEGURO E LICENCIAMENTO DE VEICULOS",
        "42 - SERVICOS DE PROCESSAMENTO DE DADOS", "10 - LOCACAO DE IMOVEIS",
        "74 - OUTROS SERVICOS DE TERCEIROS, PESSOA JURIDICA", "8 - MANUTENCAO DE SOFTWARE",
        "16 - MANUTENCAO E CONSERVACAO DE VEICULOS", "5 - SERVICOS TECNICOS PROFISSIONAIS",
        "32 - SUPRIMENTO DE AVIACAO", "65 - SERVICOS DE COPIAS E REPRODUCAO DE DOCUMENTOS",
        "76 - PUBLICACAO DE EDITAIS E ATAS", "34 - SERVICO DE SELECAO E TREINAMENTO",
        "2 - EDIFICACOES", "12 - LOCACAO DE MAQUINAS E EQUIPAMENTOS",
        "20 - MATERIAL DE CAMA, MESA E BANHO", "24 - REPAROS CONSERVACAO DE IMOVEIS E EQUIPAMENTOS",
        "14 - MATERIAL EDUCATIVO E ESPORTIVO", "61 - LIMPEZA E CONSERVACAO",
        "99 - OUTROS SERVICOS DE TERCEIROS", "44 - SERVICOS DE AUDIO, VIDEO E FOTO",
        "77 - LOCAÇÃO DE VEÍCULOS", "15 - MATERIAL PARA FESTIVIDADES E HOMENAGENS",
        "66 - SERVICOS EM ITENS REPARAVEIS DE AVIACAO", "24 - MOBILIARIO EM GERAL",
        "12 - EQUIPAMENTO DE PROTECAO, SEGURANCA E SOCORRO", "3 - TCR",
        "6 - ALIMENTOS PARA ANIMAIS", "20 - FESTIVIDADES E HOMENAGENS",
        "10 - MATERIAL ODONTOLOGICO", "99 - OUTRAS OBRIGACOES TRIBUTARIAS E CONTRIBUTATIVAS",
        "5 - APARELHOS E EQUIPAMENTOS PARA ESPORTES E DIVERSOES", "23 - MAQUINAS E EQUIPAMENTOS AGRICOLAS E RODOVIARIOS"
    ]
}

function Empenhos() {
    const [fonte, setFonte] = useState()
    const [empenhos, setEmpenhos] = useState([])
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [filtros, setFiltros] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchEmpenhos = async (page, fonte, filtros, setEmpenhos, setTotalPages, setLoading) => {
        setLoading(true)
        try {
            const params = {
                page,
                fonte,
                filtros: filtros.map(filtro => ({ tipo: filtro.tipo, opcao: filtro.opcao }))
            }

            const response = await axios.get('/api/dal/getEmpenhos', { params })

            setEmpenhos(response.data.empenhos)
            setTotalPages(response.data.totalPages)
        } catch (error) {
            console.error('Erro ao buscar empenhos:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (fonte) {
            fetchEmpenhos(page, fonte, filtros, setEmpenhos, setTotalPages, setLoading)
        }
    }, [page, fonte, filtros])

    const handleFonteChange = (e) => {
        const selectedFonte = e.target.value
        const fonteValue = selectedFonte === "CBM" ? 23101 : selectedFonte === "FUNESBOM" ? 23901 : null
        setFonte(fonteValue)
        setPage(1)
    }

    const addQtdFiltros = (e) => {
        setFiltros(prevFiltros => {
            if (e.target.name === "mais") return [...prevFiltros, { tipo: "", opcao: "" }]
            return prevFiltros.length > 1 ? prevFiltros.slice(0, -1) : prevFiltros
        })
    }

    const updateFiltro = (index, tipo) => {
        const updatedFiltros = [...filtros]
        updatedFiltros[index] = { ...updatedFiltros[index], tipo, opcao: "" }
        setFiltros(updatedFiltros)
    }

    let debounceTimeout

    const updateFiltroOpcao = (index, opcao) => {
        if (filtros[index].tipo === "HISTÓRICO" || filtros[index].tipo === "CREDOR" ||
            filtros[index].tipo === "CNPJ/CPF CREDOR" || filtros[index].tipo === "Nº PROCESSO") {

            clearTimeout(debounceTimeout)

            debounceTimeout = setTimeout(() => {
                const updatedFiltros = [...filtros]
                updatedFiltros[index].opcao = opcao
                setFiltros(updatedFiltros)
            }, 800)
        } else {
            const updatedFiltros = [...filtros]
            updatedFiltros[index].opcao = opcao
            setFiltros(updatedFiltros)
        }
    }

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1)
        }
    }

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }

    return (
        <div>
            <div style={{ margin: "1vw 0 0 1vw" }}>
                <Select texto="Fonte" nome="fonte" valor={fonte}
                    funcao={handleFonteChange} widthWeb="26vw" widthMobile="80vw"
                    options={["CBM", "FUNESBOM"]} />
            </div>

            <div style={{ margin: "1vw 0 0 1vw" }}>
                <button style={{
                    width: mediaWeb ? "4vw" : "10vw",
                    backgroundColor: "#E90303", color: "#FFFF",
                    border: "none", borderRadius: "5px"
                }}
                    name="menos"
                    onClick={addQtdFiltros}>-</button>
                <input style={{
                    marginLeft: "0.5vw", marginRight: "0.5vw", width: mediaWeb ? "4vw" : "10vw",
                    borderRadius: "5px", backgroundColor: "#00000000", textAlign: "center"
                }} value={filtros.length} disabled />
                <button style={{
                    width: mediaWeb ? "4vw" : "10vw", marginRight: "0.5vw",
                    backgroundColor: "#42A908", color: "#FFFF",
                    border: "none", borderRadius: "5px"
                }}
                    name="mais"
                    onClick={addQtdFiltros}>+</button>

                {filtros.map((filtro, index) => (
                    <div key={index}>
                        <Select texto="Filtro" nome="filtro" valor={filtro.tipo}
                            funcao={(e) => updateFiltro(index, e.target.value)}
                            widthWeb="26vw" widthMobile="80vw"
                            options={["PROGRAMA", "AÇÃO", "NATUREZA", "FINALIDADE", "TIPO NE",
                                "GRUPO FINANCEIRO", "ITEM DA DESPESA",
                                "HISTÓRICO", "CREDOR", "CNPJ/CPF CREDOR", "Nº PROCESSO"
                            ]} />
                        {filtro.tipo !== "HISTÓRICO" &&
                            filtro.tipo !== "CREDOR" &&
                            filtro.tipo !== "CNPJ/CPF CREDOR" &&
                            filtro.tipo !== "Nº PROCESSO" ? (
                            <Select texto="Opção" nome="filtroOpcao" valor={filtro.opcao}
                                funcao={(e) => updateFiltroOpcao(index, e.target.value)}
                                widthWeb="26vw" widthMobile="80vw"
                                options={optionsFiltro[filtro.tipo]} />
                        ) : (
                            <Input texto="Opção" nome="filtroOpcao" valor={filtro.opcao}
                                funcao={(e) => updateFiltroOpcao(index, e.target.value)}
                                widthWeb="26vw" widthMobile="80vw" />
                        )}
                    </div>
                ))}
            </div>

            {loading ? (
                <p>Carregando...</p>
            ) : (
                <div style={{ position: mediaWeb ? "relative" : null, overflowX: mediaWeb ? null : "auto" }}>
                    <table className="tableDfd">
                        <thead>
                            <tr>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Unidade</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Função</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Subfunção</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Programa</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Ação</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Natureza</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Fonte</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Finalidade</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Valor NE</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Suplementado</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Anulado</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Liquidado</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Pag. Anulado</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Valor Pago</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Valor Atualizado NE</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>A Pagar</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Número Empenho</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>NE Origem</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Data NE</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Tipo NE</th>
                                <th style={{ width: mediaWeb ? "6vw" : "25vw" }}>Licitação</th>
                                <th style={{ width: mediaWeb ? "30vw" : "60vw" }}>Histórico</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Credor</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>CNPJ/CPF Credor</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Tipo Credor</th>
                                <th style={{ width: mediaWeb ? "10vw" : "30vw" }}>Código Credor</th>
                                <th style={{ width: mediaWeb ? "15vw" : "45vw" }}>Grupo Financeiro</th>
                                <th style={{ width: mediaWeb ? "15vw" : "45vw" }}>Cadastro CGE</th>
                                <th style={{ width: mediaWeb ? "15vw" : "45vw" }}>Número Processo</th>
                                <th style={{ width: mediaWeb ? "15vw" : "45vw" }}>Contrato</th>
                                <th style={{ width: mediaWeb ? "15vw" : "45vw" }}>Reserva</th>
                                <th style={{ width: mediaWeb ? "15vw" : "45vw" }}>Item Despesa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {empenhos.map((empenho, index) => (
                                <tr key={index}>
                                    <td>{empenho.unidade}</td>
                                    <td>{empenho.funcao}</td>
                                    <td>{empenho.subfuncao}</td>
                                    <td>{empenho.programa}</td>
                                    <td>{empenho.acao}</td>
                                    <td>{empenho.natureza}</td>
                                    <td>{empenho.fonte}</td>
                                    <td>{empenho.finalidade}</td>
                                    <td>{formatCurrency(empenho.valorNe)}</td>
                                    <td>{formatCurrency(empenho.suplementado)}</td>
                                    <td>{formatCurrency(empenho.anulado)}</td>
                                    <td>{formatCurrency(empenho.liquidado)}</td>
                                    <td>{formatCurrency(empenho.pagAnulado)}</td>
                                    <td>{formatCurrency(empenho.valorPago)}</td>
                                    <td>{formatCurrency(empenho.valorAtualizadoNe)}</td>
                                    <td>{formatCurrency(empenho.aPagar)}</td>
                                    <td>{empenho.numeroEmpenho}</td>
                                    <td>{empenho.neOrigem}</td>
                                    <td>{empenho.dataNe ? converterData(empenho.dataNe.slice(0, 10)) : ''}</td>
                                    <td>{empenho.tipoNe}</td>
                                    <td>{empenho.licitacao}</td>
                                    <td>{empenho.historico}</td>
                                    <td>{empenho.credor}</td>
                                    <td>{empenho.cnpjCpfCredor}</td>
                                    <td>{empenho.tipoCredor}</td>
                                    <td>{empenho.codigoCredor}</td>
                                    <td>{empenho.grupoFinanceiro}</td>
                                    <td>{empenho.cadastroCge}</td>
                                    <td>
                                        <a href={`https://pbdoc.pb.gov.br/sigaex/app/expediente/doc/exibir?sigla=${empenho.numeroProcesso}`} target='_blank' >
                                        {empenho.numeroProcesso}
                                        </a>
                                    </td>
                                    <td>{empenho.contrato}</td>
                                    <td>{empenho.reserva}</td>
                                    <td>{empenho.itemDespesa}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            <div>
                <Botao texto="Anterior" widthWeb="12vw" widthMobile="28vw" display="inline" cor="rgb(51, 52, 143)"
                    funcao={handlePreviousPage} />
                <span>Página {page} de {totalPages}</span>
                <Botao texto="Próxima" widthWeb="12vw" widthMobile="28vw" display="inline" cor="rgb(51, 52, 143)" marginL="1vw"
                    funcao={handleNextPage} />
            </div>
        </div>
    )
}

export default Empenhos
