import { useState, useEffect } from 'react'
import axios from 'axios'
import converterData from '../../Biblioteca/Funcoes/converterData'
import Botao from '../../Biblioteca/Botao'

const GetDfdExtra = () => {
    const [dados, setDados] = useState([])
    const [paginaAtual, setPaginaAtual] = useState(1)
    const itensPorPagina = 10

    const fetchDados = (pagina) => {
        axios.get(`/api/dfdExtra/getDfdsExtra?page=${pagina}`)
            .then(res => setDados(res.data))
            .catch(error => alert(error.response.data.message))
    }

    useEffect(() => {
        fetchDados(paginaAtual)
    }, [paginaAtual])

    const paginaAnterior = () => {
        if (paginaAtual > 1) setPaginaAtual(paginaAtual - 1)
    }

    const proximaPagina = () => {
        if (dados.length === itensPorPagina) setPaginaAtual(paginaAtual + 1)
    }

    return (
        <>
            <div style={{ width: "90vw", margin: "4vh auto" }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th style={{ width: "20vw", textAlign: "center" }}>Regional</th>
                            <th style={{ width: "20vw", textAlign: "center" }}>Fonte</th>
                            <th style={{ width: "20vw", textAlign: "center" }}>Data</th>
                            {/* <th style={{ width: "20vw", textAlign: "center" }}>Itens Removidos</th>
                            <th style={{ width: "20vw", textAlign: "center" }}>Itens Adicionados</th> */}
                            <th style={{ width: "20vw", textAlign: "center" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dados.map((el, index) => {
                            const totalRemovidos = el.itensRemovidos?.reduce((acc, item) => acc + (item.qtdRemovida || 0), 0) || 0
                            const totalAdicionados = el.itensAdicionados?.reduce((acc, item) => acc + (item.qtdAdicionada || 0), 0) || 0
                            return (
                                <tr key={`dfdExtra-${index}`}>
                                    <td style={{ textAlign: "center" }}>{el.regional}</td>
                                    <td style={{ textAlign: "center" }}>{el.fonte}</td>
                                    <td style={{ textAlign: "center" }}>{converterData(el?.data.slice(0, 10))}</td>
                                    {/* <td style={{ textAlign: "center" }}>{totalRemovidos}</td>
                                    <td style={{ textAlign: "center" }}>{totalAdicionados}</td> */}
                                    <td style={{ textAlign: "center" }}>
                                        <Botao
                                            texto="Abrir"
                                            funcao={() => window.location = `/DfdExtraView/${el._id}`}
                                            widthMobile="40vw"
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Botao
                        texto="Anterior"
                        funcao={paginaAnterior}
                        disabled={paginaAtual === 1}
                        marginT="0"
                        marginR="0"
                        cor="rgb(51, 52, 143)"
                    />
                    <span style={{ margin: '0 15px' }}>Página {paginaAtual}</span>
                    <Botao
                        texto="Próxima"
                        funcao={proximaPagina}
                        disabled={dados.length < itensPorPagina}
                        marginT="0"
                        cor="rgb(51, 52, 143)"
                    />
                </div>
            </div>
        </>
    )
}

export default GetDfdExtra
