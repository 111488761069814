import { useState, useEffect, useRef, useReducer } from 'react'
import axios from 'axios'

import Select from '../../Biblioteca/Select'
import Textarea from '../../Biblioteca/Textarea'
import Input from '../../Biblioteca/Input'
import Botao from '../../Biblioteca/Botao'
import formatCurrency from '../../Biblioteca/Funcoes/formatCurrency'

const dataAtual = new Date()
const dia = String(dataAtual.getDate()).padStart(2, '0')
const mes = String(dataAtual.getMonth() + 1).padStart(2, '0')
const currentYear = dataAtual.getFullYear()
const dataFormatada = `${dia}/${mes}/${currentYear}`

const SelecionarModelo = () => {
    const [modelo, setModelo] = useState("")
    const [dados, setDados] = useState({
        unidadeGestora: "",
        fonteRecursos: "",
        programaTrabalho: "5005 - Paraíba mais Segura",
        elementoDespesa: "",
    })
    const [search, setSearch] = useState()
    const [searchResults, setSearchResults] = useState([])
    const [itensEscolhidos, setItensEscolhidos] = useState([])
    const [selectKey, setSelectKey] = useState(0)
    const textAreaRefs = useRef([])
    const [selectOptions, setSelectOptions] = useState([1])
    const [somaQtdKey, setSomaQtdKey] = useState(0)
    const id = window.location.pathname.slice(18)
console.log(itensEscolhidos)
    const fetchDfdSead = async (id) => {
        try {
            const res = await axios.get(`/api/pca/getDfdSead/${id}`)
            setDados(res.data.dfd)
            setItensEscolhidos(res.data.dfd.itensEscolhidos)
            setSelectKey(prevKey => prevKey + 1)
        } catch (error) {
            alert("Erro ao buscar DfdSead:", error.response?.data?.message || error.message)
        }
    }

    useEffect(() => {
        if (id && id.trim() !== "") {
            fetchDfdSead(id)
        }
    }, [id])

    const updateModelo = (e) => {
        const { value } = e.target
        setModelo(value)
    }
    const updateSearch = (e) => {
        const { value } = e.target
        setSearch(value)
    }

    const updateField = (e) => {
        const { name, value } = e.target
        let tempState = { ...dados }
        tempState[name] = value
        setDados(tempState)
    }

    const buscaItem = () => {
        setSelectKey(prevKey => prevKey + 1)
        axios.post('/api/pca/buscarItemDfdSead', { item: search })
            .then(res => {
                setSearchResults(res.data)

                setSelectOptions(prev => {
                    const maxLinha = itensEscolhidos.length + 1
                    return Array.from({ length: maxLinha }, (_, i) => i + 1)
                })
            })
            .catch(error => {
                alert(error.response.data.message)
                setSearchResults([])
            })
    }

    const limparBusca = () => { setSearchResults([]) }

    const ajustarAltura = (textarea) => {
        if (textarea) {
            textarea.style.height = 'auto'
            textarea.style.height = textarea.scrollHeight + 'px'
        }
    }

    useEffect(() => {
        textAreaRefs.current.forEach(ajustarAltura)
        setSomaQtdKey(prevKey => prevKey + 1)
    }, [itensEscolhidos])

    const atualizarJustificativa = (linha) => {
        const regionaisPorAno = linha.itens.reduce((acc, item) => {
            const ano = item.anoPreenchimentoPca
            acc[ano] = acc[ano] || []
            acc[ano].push(`${item.regionais.map(reg => `${reg.regional}(${reg.qtdEstimada})`).join(', ')}`)
            return acc
        }, {})

        return `
    Conforme Plano de Distribuição e quantidades constantes nos Documentos de Formalização das Demandas dos Níveis Setoriais abaixo descritos, consolidam as seguintes quantidades agrupadas no Documento de Formalização da Demanda Consolidado que foi anexado por ocasião do encaminhamento do(s) Plano(s) Contratações Anuais:\n\n
    ${Object.keys(regionaisPorAno)
                .map(ano => `PCA ${ano}: ${regionaisPorAno[ano].join(', ')}`)
                .join('\n\n')}`
    }

    const adicionarNaLinha = (event, item) => {
        const valor = parseInt(event.target.value)
        
        setItensEscolhidos((prev) => {
            const fontesExistentes = new Set(prev.flatMap(linha => linha.itens.map(i => i.fonte)))

            if (fontesExistentes.size > 0 && !fontesExistentes.has(item.fonte)) {
                alert("Atenção, o item não foi adicionado, mais de uma FONTE foi selecionada para os itens, apenas uma FONTE pode ser utilizada para cada DFD!")
                return prev
            }

            const novaLista = prev.map((linha) => ({
                ...linha,
                itens: linha.itens.filter(
                    (i) =>
                        !(
                            i.objeto === item.objeto &&
                            i.fonte === item.fonte &&
                            i.descricao === item.descricao &&
                            i.anoPreenchimentoPca === item.anoPreenchimentoPca
                        )
                ),
            })).filter((linha) => linha.itens.length > 0)

            const linhaExistente = novaLista.find((linha) => linha.linha === valor)

            if (linhaExistente) {
                linhaExistente.itens.push(item)
                linhaExistente.justificativaItem = atualizarJustificativa(linhaExistente)
                linhaExistente.qtdSolicitadaItem = linhaExistente.itens.reduce((acc, curr) => acc + (curr.qtdEstimada || 0), 0).toString()
                return [...novaLista]
            } else {
                const novaQtdEstimada = item.qtdEstimada || 0
                return [
                    ...novaLista,
                    {
                        linha: valor,
                        itens: [item],
                        justificativaItem: atualizarJustificativa({ itens: [item] }).trim(),
                        qtdSolicitadaItem: novaQtdEstimada.toString()
                    },
                ]
            }
        })
    }

    const handleInputChange = (index, name, value) => {
        setItensEscolhidos((prev) => {
            const novaLista = [...prev]
            novaLista[index][name] = value
            return novaLista
        })
    }

    useEffect(() => {
        if (itensEscolhidos.length > 0) {
            const fontes = [...new Set(itensEscolhidos.flatMap(linha => linha.itens.map(i => i.fonte)))]
            let unidadeGestora = ""
            let fonteRecursos = ""

            if (fontes.includes("CBM")) {
                unidadeGestora = "23101 - Comando Geral do Corpo de Bombeiros Militar"
                fonteRecursos = "500 - Recursos do Tesouro Estadual"
            }

            if (fontes.includes("FUNESBOM")) {
                unidadeGestora = "23901 - Fundo Especial do Corpo de Bombeiros"
                fonteRecursos = "759 - FUNESBOM"
            }

            const naturezasDespesas = [...new Set(itensEscolhidos.flatMap(linha => linha.itens.map(i => i.natureza)))]

            setDados(prev => ({
                ...prev,
                unidadeGestora: unidadeGestora,
                fonteRecursos: fonteRecursos,
                elementoDespesa: naturezasDespesas.join(", ")
            }))
        }

        setSelectOptions(Array.from({ length: itensEscolhidos.length + 1 }, (_, i) => i + 1))
    }, [itensEscolhidos])

    const enviarDados = () => {
        if (id && id.trim() !== "") var url = '/api/pca/editDfdSead'
        else var url = '/api/pca/novoDfdSead'
        axios.post(url, { modelo, ...dados, itensEscolhidos: itensEscolhidos, data: dataFormatada })
            .then(res => {
                alert(res.data.message)
                window.location = `/DfdSeadView/${res.data.id}`
            })
            .catch(error => {
                alert(error.response.data.message)
            })
    }

    return (
        <>
            <div style={{ margin: "1vw 4vw 0 4vw" }} key={selectKey}>
                <Select texto="Modelo do DFD" nome="modelo" display="inline-block" valor={dados.modelo}
                    funcao={updateModelo} widthWeb="26vw" widthMobile="80vw"
                    options={["Processo Interno", "Registro de Preço", "Pregão"]} />
                <Select texto="Estratégia de Aquisição" nome="estrategiaAquisicao" display="inline-block"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw" valor={dados.estrategiaAquisicao}
                    options={[
                        "Dispensa de licitação", "Inexigibilidade", "Pequenas compras/Pronto pagamento", "Intençao de Registro de Preço",
                        "Adesão a ata de registro de preço", "Pregão para registro de preço", "Utilização de ata", 
                        "Aditivo de Tempo", "Aditivo de Valor", "Aditivo de Tempo e Valor"
                    ]} />
                <div>
                    <Input texto="Assunto" nome="assunto" widthWeb="26vw" widthMobile="80vw" display="inline-block"
                        funcao={updateField} valor={dados.assunto} />
                    <Input texto="PBDOC" nome="pbdoc" widthWeb="26vw" widthMobile="80vw" display="inline-block"
                        funcao={updateField} valor={dados.pbdoc} />
                    {dados.numeroDfd ? (
                        <Input texto="Número do DFD" nome="numeroDfd" valor={dados.numeroDfd}
                            widthWeb="26vw" widthMobile="80vw" display="inline-block"
                            tipo="number" funcao={updateField} />
                    ) : (<></>)}
                </div>
                <hr />
                <Textarea texto="Identificação da Demanda" nome="identificacao" funcao={updateField} display="inline-block" default={dados.identificacao}
                    placeholder="IDENTIFICAR O OBJETO DA DEMANDA, OU SEJA, AQUILO QUE SE VISA CONTRATAR" />
                <hr />
                <Textarea texto="Justificativa" nome="justificativa" funcao={updateField} display="inline-block"
                    placeholder="" default={dados.justificativa} />
                <hr />
                <div style={{ fontWeight: 600, fontSize: 15 }}>Escolha dos Itens</div>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "26vw auto auto", alignItems: "flex-end", justifyContent: "flex-start",
                    height: "100%", width: "auto", columnGap: "2vw"
                }}>
                    <Input texto="Buscar Item" nome="item" widthWeb="26vw" widthMobile="80vw"
                        marginR="1vw" funcao={updateSearch} />
                    <Botao texto="BUSCAR" funcao={buscaItem} marginB="0.5vw" marginT="0vw"
                        align="left" widthMobile="40vw" display="inline-grid" />
                    <Botao texto="Limpar" funcao={limparBusca} marginB="0.5vw" marginT="0vw"
                        align="left" widthMobile="40vw" display="inline-grid" />
                </div>
                <table style={{ fontSize: "12px" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center", width: "10vw" }}>ITEM</th>
                            <th style={{ textAlign: "center", width: "30vw" }}>DESCRIÇÃO</th>
                            <th style={{ textAlign: "center" }}>{currentYear - 3}</th>
                            <th style={{ textAlign: "center" }}>{currentYear - 2}</th>
                            <th style={{ textAlign: "center" }}>{currentYear - 1}</th>
                            <th style={{ textAlign: "center" }}>SOLICITADO</th>
                            <th style={{ textAlign: "center" }}>VALOR</th>
                            <th style={{ textAlign: "center" }}>PCA</th>
                            <th style={{ textAlign: "center" }}>FONTE</th>
                            <th style={{ textAlign: "center" }}>ADICIONAR NA LINHA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map((item, index) => {
                            const descricao = item.descricao.length > 200
                                ? item.descricao.slice(0, 100) + "... (Ver tabela básica SGC)"
                                : item.descricao

                            return (
                                <tr key={index}>
                                    <td style={{ textAlign: "center" }}>{item.objeto}</td>
                                    <td style={{ textAlign: "center" }}>{descricao}</td>
                                    <td style={{ textAlign: "center" }}>{item.ano3}</td>
                                    <td style={{ textAlign: "center" }}>{item.ano2}</td>
                                    <td style={{ textAlign: "center" }}>{item.ano1}</td>
                                    <td style={{ textAlign: "center" }}>{item.qtdEstimada}</td>
                                    <td style={{ textAlign: "center" }}>{formatCurrency(item.qtdEstimada * item.valorEstimado)}</td>
                                    <td style={{ textAlign: "center" }}>{item.anoPreenchimentoPca}</td>
                                    <td style={{ textAlign: "center" }}>{item.fonte}</td>
                                    <td style={{ textAlign: "center" }}>
                                        <Select nome="adicionarLinha" widthWeb="4vw" key={`${selectKey}-${index}`}
                                            funcao={(event) => adicionarNaLinha(event, item)}
                                            options={selectOptions} />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <table style={{ marginTop: "2vh", width: "100%" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center", width: "20%" }}>ITEM</th>
                            <th style={{ textAlign: "center", width: "10%" }}>{currentYear - 3}</th>
                            <th style={{ textAlign: "center", width: "10%" }}>{currentYear - 2}</th>
                            <th style={{ textAlign: "center", width: "10%" }}>{currentYear - 1}</th>
                            {modelo === "Processo Interno" ? (
                                <th style={{ textAlign: "center", width: "20%" }}>QUANTIDADE SOLICITADA</th>
                            ) : (<></>)}
                            <th style={{ textAlign: "center", width: "30%" }}>JUSTIFICATIVA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itensEscolhidos.map((linha, index) => {
                            const somaAno1 = linha.itens.reduce((acc, curr) => acc + (curr.ano1 || 0), 0)
                            const somaAno2 = linha.itens.reduce((acc, curr) => acc + (curr.ano2 || 0), 0)
                            const somaAno3 = linha.itens.reduce((acc, curr) => acc + (curr.ano3 || 0), 0)
                            
                            const regionaisPorAno = {}

                            linha.itens.forEach(item => {
                                const ano = item.anoPreenchimentoPca
                                if (!regionaisPorAno[ano]) {
                                    regionaisPorAno[ano] = []
                                }
                                regionaisPorAno[ano].push(...item.regionais.map(reg => `${reg.regional}(${reg.qtdEstimada})`))
                            })

                            return (
                                <tr key={index}>
                                    <td style={{ textAlign: "center", width: "20%" }}>
                                        <textarea
                                            ref={(el) => textAreaRefs.current[index] = el}
                                            className="input-objeto-descricao"
                                            value={linha.objetoItem}
                                            onChange={(e) => handleInputChange(index, "objetoItem", e.target.value)}
                                            style={{ width: '100%', overflow: 'hidden', resize: 'none' }}
                                        />
                                    </td>
                                    <td style={{ textAlign: "center", width: "10%" }}>{somaAno3}</td>
                                    <td style={{ textAlign: "center", width: "10%" }}>{somaAno2}</td>
                                    <td style={{ textAlign: "center", width: "10%" }}>{somaAno1}</td>
                                    {modelo === "Processo Interno" || dados.modelo === "Processo Interno" ? (
                                        <td style={{ textAlign: "center", width: "20%" }}>
                                            <input className="input-objeto-qtdSolicitadaItem"
                                                value={linha.qtdSolicitadaItem} type="number"
                                                onChange={(e) => handleInputChange(index, "qtdSolicitadaItem", e.target.value)}
                                                style={{ width: '100%', border: "1px solid #00000029", borderRadius: "5px", backgroundColor: "#00000000" }} />
                                        </td>
                                    ) : (<></>)}
                                    <td style={{ textAlign: "left", width: "30%" }}>
                                        <textarea
                                            ref={(el) => textAreaRefs.current[index] = el}
                                            className="input-objeto-descricao"
                                            value={linha.justificativaItem}
                                            onChange={(e) => handleInputChange(index, "justificativaItem", e.target.value)}
                                            style={{ width: '100%', overflow: 'hidden', resize: 'none' }}
                                        />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <hr />
                <div style={{ fontWeight: 600, fontSize: 15 }}>Previsão de Entrega</div>
                <div>
                    <Select texto="Mês" nome="mes" valor={dados.mes}
                        funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                        options={[
                            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
                            "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
                        ]} />
                    <Select texto="Ano" nome="ano" valor={dados.ano}
                        funcao={updateField} widthWeb="26vw" widthMobile="80vw"
                        options={[2024, 2025, 2026]} />
                </div>
                <hr />
                {modelo === "Processo Interno" || dados.modelo === "Processo Interno" ? (
                    <>
                        <div style={{ fontWeight: 600, fontSize: 15 }}>Fonte de Recursos</div>
                        <Input texto="Gestão/Unidade" nome="unidadeGestora" widthWeb="26vw" widthMobile="80vw"
                            display="block" valor={dados.unidadeGestora} funcao={updateField} />
                        <Input texto="Fonte de Recursos" nome="fonteRecursos" widthWeb="26vw" widthMobile="80vw"
                            display="block" valor={dados.fonteRecursos} funcao={updateField} />
                        <Input texto="Programa de Trabalho" nome="programaTrabalho" widthWeb="26vw" widthMobile="80vw"
                            display="block" valor={dados.programaTrabalho} funcao={updateField} />
                        <Input texto="Elemento de Despesa" nome="elementoDespesa" widthWeb="26vw" widthMobile="80vw" key={`${somaQtdKey}`}
                            display="block" valor={dados.elementoDespesa} funcao={updateField} />
                        <hr />
                    </>
                ) : (<></>)}
                <Select texto="Declaramos que todos os itens indicados neste documento de formalização da demanda:" nome="constaPca" display="inline-block"
                    funcao={updateField} widthWeb="26vw" widthMobile="80vw" valor={dados.constaPca}
                    options={["Constam no Plano de Contratações Anual – PCA", "Não constam no Plano de Contratações Anual - PCA"]} />
                {dados.constaPca && dados.constaPca === "Não constam no Plano de Contratações Anual - PCA" ? (
                    <Textarea nome="justificativaPca" funcao={updateField} display="inline-block" default={dados.justificativaPca}
                        placeholder="CASO A CONTRATAÇÃO NÃO ESTEJA PREVISTA NO PCA, INSERIR AQUI A JUSTIFICATIVA COM A EXPOSIÇÃO DOS MOTIVOS DA CONTRATAÇÃO NÃO ESTAR PREVISTA" />
                ) : (<></>)}
                <hr />
                <Botao texto="Enviar" funcao={enviarDados} widthMobile="40vw" marginB="4vh" />
            </div>
        </>
    )
}

export default SelecionarModelo